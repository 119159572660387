import React, { useEffect, useState, memo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { countries, EMPTY_ARRAY, getCountryCode } from '../../../utils/utils';
import { textFieldStyle, autocompleteStyle, compactStyle } from './styles';

function CountryAutocomplete({
  label,
  value,
  error,
  handleChange,
  showInput = false,
  hide,
  dependsOn,
  filterCountries = EMPTY_ARRAY,
  compact,
}) {
  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState(value);
  const [previousDependsOnValue, setPreviousDependsOnValue] = useState(dependsOn);
  useEffect(() => {
    if (value) {
      setOptions([value]);
      setCurrentValue(value);
    } else {
      const initialCountries = countries
        .filter(c => !filterCountries.includes(c))
        .sort((a, b) => (a.toUpperCase() > b.toUpperCase() ? 1 : -1))
        .slice(0, 5);
      setOptions(initialCountries);
    }
  }, [value, filterCountries]);

  useEffect(() => {
    if (dependsOn !== undefined && dependsOn !== previousDependsOnValue) {
      setPreviousDependsOnValue(dependsOn);
      setCurrentValue('');
      setOptions([]);
      handleChange('');
    }
  }, [dependsOn, handleChange, previousDependsOnValue]);

  const onInputChange = (event, text) => {
    const regex = RegExp('^' + text, 'i');
    const filtered = countries
      .filter(c => regex.test(c) && !filterCountries.includes(c))
      .slice(0, 5);
    filtered.sort((a, b) => (a > b ? 1 : -1));
    setOptions(filtered);
  };

  const handleSelect = (event, newValue) => {
    handleChange(newValue ? { code: getCountryCode(newValue), name: newValue } : {});
    setCurrentValue(newValue);
  };

  return hide ? null : showInput ? (
    <TextField
      value={value || ''}
      label={label}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      style={textFieldStyle}
    />
  ) : (
    <Autocomplete
      value={currentValue || null}
      options={options}
      onInputChange={onInputChange}
      onChange={(event, newValue) => handleSelect(event, newValue)}
      autoHighlight
      style={autocompleteStyle}
      noOptionsText="No hay opciones"
      openText="Abrir"
      clearText="Borrar"
      closeText="Cerrar"
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          style={compact && compactStyle}
          margin="normal"
          error={error !== undefined}
          helperText={error}
        />
      )}
    />
  );
}

export default memo(CountryAutocomplete);
