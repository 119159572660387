import React, { useCallback, useEffect, useState, memo } from 'react';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { EMPTY_ARRAY } from '../../../utils/utils';
import { get, handleResponse, swal500 } from '../../../utils/network';
import useDebounce from '../useDebounce';
import { textFieldStyle, autocompleteStyle, compactStyle } from './styles';

function ApiAutocomplete({
  value,
  label,
  field,
  error,
  path,
  handleChange,
  disabled = false,
  showInput = false,
  likeLocale = true,
  dependsOn,
  hide,
  freeSolo,
  filterIds = EMPTY_ARRAY,
  idField = '_id',
  compact,
  helperText,
}) {
  const history = useHistory();
  const [optionLabels, setOptionLabels] = useState([]);
  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [previousDependsOnValue, setPreviousDependsOnValue] = useState(dependsOn);
  const basePathWithParams = path && `${path}${path.includes('?') ? '&' : '?'}page=0&size=30`;

  const fillAutocomplete = useCallback(
    newValue => {
      if (!basePathWithParams) return;
      const regex = new RegExp(' ');
      const nameParam = newValue.replace(regex, '%20');
      setLoading(true);
      const like = likeLocale ? 'likeLocale' : 'like';
      const endpoint = nameParam
        ? `${basePathWithParams}&${field}=${like}:${nameParam}`
        : basePathWithParams;
      get(endpoint)
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          const items = parsed.message.data.filter(
            i => i[field] && !filterIds.includes(i[idField])
          );
          if (freeSolo && items.length === 0) {
            setOptions([{ name: newValue }]);
            setOptionLabels([`Agregar "${newValue}"`]);
          } else {
            setOptions(items);
            setOptionLabels(items.map(option => option[field]));
          }
        })
        .catch(swal500)
        .finally(() => setLoading(false));
    },
    [basePathWithParams, field, history, likeLocale, freeSolo, idField, filterIds]
  );

  useEffect(() => {
    if (value && value[field]) {
      setCurrentValue(value[field]);
      setOptions([value]);
      setOptionLabels([value[field]]);
    } else if (!disabled) {
      fillAutocomplete('');
    }
  }, [disabled, field, value, fillAutocomplete]);

  useEffect(() => {
    if (dependsOn !== undefined && dependsOn !== previousDependsOnValue) {
      setPreviousDependsOnValue(dependsOn);
      setCurrentValue('');
      setOptions([]);
      setOptionLabels([]);
      handleChange('');
      fillAutocomplete('');
    }
  }, [dependsOn, disabled, fillAutocomplete, previousDependsOnValue, handleChange]);

  const handleInputChange = (event, newValue) => {
    setCurrentValue(newValue);
    setLoading(true);
    setOptions([]);
    setOptionLabels([]);
    fillAutocomplete(newValue);
  };

  const onInputChange = useDebounce(handleInputChange, 400);

  const handleSelect = (event, newValue) => {
    let selected;
    if (newValue?.startsWith('Agregar')) selected = options[0];
    else selected = options.filter(o => o[field] === newValue)[0];
    if (selected || !newValue) handleChange(selected || '');
  };

  return hide ? null : showInput ? (
    <TextField
      value={value[field] || value}
      label={label}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      style={textFieldStyle}
    />
  ) : (
    <Autocomplete
      options={optionLabels}
      autoHighlight
      disabled={disabled}
      value={currentValue || null}
      onInputChange={onInputChange}
      onChange={(event, newValue) => handleSelect(event, newValue)}
      loading={loading}
      getOptionSelected={(option, value) => isEqual(option, value)}
      getOptionLabel={option => {
        if (freeSolo && option?.startsWith('Agregar')) return option.split('"')[1];
        else return option;
      }}
      loadingText="Cargando..."
      noOptionsText="No hay opciones"
      openText="Abrir"
      clearText="Borrar"
      closeText="Cerrar"
      freeSolo={freeSolo}
      style={autocompleteStyle}
      renderInput={params => (
        <form>
          <input
            type="password"
            style={{
              width: 0,
              height: 0,
              visibility: 'hidden',
              position: 'absolute',
              left: 0,
              top: 0,
            }}
          />
          <TextField
            {...params}
            style={compact && compactStyle}
            label={label}
            margin="normal"
            error={error !== undefined}
            helperText={helperText || error}
          />
        </form>
      )}
    />
  );
}

export default memo(ApiAutocomplete);
