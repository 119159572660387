export const AllPermissions = {
  all: '5ee8d23c798dc64752c2cf01',
  viewUsers: '5ee8d282798dc64752c2cf02',
  manageUsers: '5ee91401fd71adae46a45bfc',
  viewRoles: '5ee91c3afd71adae46a45bfe',
  manageRoles: '5ee915b8fd71adae46a45bfd',
  viewPermissions: '5eea67579873c743a0a7f5da',
  manageOthersSessions: '5efb9e9af62305f8d0153890',
  manageTreatments: '60400f486302367443a145d2',
  manageDiseases: '60381a1a5e109150e0fbe87d',
  manageProducts: '606defe7cd285be8f16f6fdb',
  manageDrugFunctions: '6095a3ea8e920904f33eca6e',
  manageCategories: '60a579f4901700c2cbe42d3e',
  manageGenerics: '6099b5033098e4523c30686e',
};
