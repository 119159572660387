import React, { memo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  CircularProgress,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TitleNoItems } from '..';

const useStyles = makeStyles({
  accordionContainer: { marginBottom: 8 },
  loadingContainer: { display: 'flex', alignItems: 'center', padding: '10px 0' },
  loadingText: { fontSize: 16, paddingLeft: 10 },
});

const CustomAccordion = memo(
  ({ category, handleChangeCategory, handleChangePermission, editMode }) => {
    const allPermissionsChecked = category.permissions.every(p => p.checked);
    const somePermissionChecked = category.permissions.some(p => p.checked);
    return (
      (editMode || somePermissionChecked) && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {editMode ? (
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={somePermissionChecked && !allPermissionsChecked}
                    checked={allPermissionsChecked}
                    onChange={e => handleChangeCategory(category.category, e)}
                    name={category.category}
                  />
                }
                label={category.category}
              />
            ) : (
              <FormLabel>{category.category}</FormLabel>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              {category.permissions.map(
                permission =>
                  (editMode || permission.checked) &&
                  (permission.disabled && editMode ? (
                    <Tooltip title="Permiso asociado al rol" placement="right" key={permission._id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!editMode || permission.disabled}
                            checked={permission.checked ? permission.checked : false} //for controlled component
                            onChange={e => handleChangePermission(category.category, e)}
                            name={permission.name}
                          />
                        }
                        label={permission.description}
                      />
                    </Tooltip>
                  ) : (
                    <FormControlLabel
                      key={permission._id}
                      control={
                        <Checkbox
                          disabled={!editMode || permission.disabled}
                          checked={permission.checked ? permission.checked : false} //for controlled component
                          onChange={e => handleChangePermission(category.category, e)}
                          name={permission.name}
                        />
                      }
                      label={permission.description}
                    />
                  ))
              )}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      )
    );
  }
);

function PermissionsSelector({
  permissions = [],
  loading,
  handleChangeCategory,
  handleChangePermission,
  editMode,
}) {
  const classes = useStyles();
  const somePermissionChecked =
    permissions.filter(category => category.permissions.some(p => p.checked)).length > 0;
  return (
    <div>
      <TitleNoItems
        title="Permisos asignados"
        text="No posee permisos"
        noItems={!(somePermissionChecked || editMode)}
      />
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" size={20} />
          <span className={classes.loadingText}>Obteniendo permisos...</span>
        </div>
      ) : (
        permissions.length > 0 && (
          <div className={classes.accordionContainer}>
            {permissions.map(category => (
              <CustomAccordion
                key={category.category}
                category={category}
                handleChangeCategory={handleChangeCategory}
                handleChangePermission={handleChangePermission}
                editMode={editMode}
              />
            ))}
          </div>
        )
      )}
    </div>
  );
}

export default memo(PermissionsSelector);
