import React from 'react';
import { LocaleSelector, TextInput } from '../../common';
import { FIELDS } from './FunctionsFormContainer';

const localeSelectorStyle = { margin: '15px 0px 0px' };

const FunctionsFormComponent = ({
  editMode,
  errors,
  handleChange,
  handleBlur,
  values,
  smallScreen,
  hasChanges,
  handleCancel,
  handleSelectedValue,
}) => {
  return (
    <>
      {smallScreen && (
        <LocaleSelector
          fullWidth
          style={localeSelectorStyle}
          setSelectedValue={handleSelectedValue}
          selectedValue={values.locale}
          hasChanges={hasChanges()}
          handleCancel={handleCancel}
        />
      )}
      <TextInput
        name="name"
        value={values.name}
        error={errors.name}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['name']}
        disabled={!editMode}
      />
    </>
  );
};

export default FunctionsFormComponent;
