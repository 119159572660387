import React from 'react';
import {
  CircularProgress,
  IconButton,
  Typography,
  withStyles,
  Card,
  Avatar,
} from '@material-ui/core';
import { Create as CreateIcon } from '@material-ui/icons';
import styles from './styles';
import { FIELDS } from './ProfileFormContainer';
import {
  Loading,
  SuccessToast,
  ErrorToast,
  TextInput,
  Surface,
  PrimaryButton,
} from '../../components/common';

const ProfileFormComponent = props => {
  const {
    classes,
    errors,
    handleChange,
    handleBlur,
    handleResult,
    // handleUploadImage,
    submitting,
    // submittingImage,
    loading,
    values,
    editMode,
    setEditMode,
    imageUrl,
    successMessage,
    errorMessage,
    onCloseToast,
    submittingPasswordReset,
    handlePasswordReset,
  } = props;

  const renderActions = () => (
    <div className={classes.topActionsContainer}>
      {!editMode && (
        <IconButton onClick={() => setEditMode(true)}>
          <CreateIcon />
        </IconButton>
      )}
    </div>
  );

  const ProfileCard = () => (
    <Card elevation={0} className={classes.profileCard}>
      <Avatar alt="Remy Sharp" src={imageUrl} className={classes.image} />
      {/* editMode && (
        <div className={classes.profileCardActions}>
          <input
            accept="image/*"
            type="file"
            onChange={handleUploadImage}
            id="upload-file"
            className={classes.inputFile}
          />
          <label className={classes.profileCardLabel} htmlFor="upload-file">
            {submittingImage && (
              <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
            )}
            <Button
              component="span"
              color="primary"
              variant="contained"
              disabled={loading || submittingImage}
              className={classes.profileCardButton}
            >
              Actualizar imagen
            </Button>
          </label>
        </div>
      )*/}
    </Card>
  );

  return (
    <div>
      <Surface className={classes.root}>
        <div className={classes.formHeader}>
          <div className={classes.titleContainer}>
            <Typography style={{ fontSize: 20 }}>Mi Perfil</Typography>
          </div>
          {renderActions()}
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.contentContainer}>
            <ProfileCard />
            <form className={classes.form}>
              <TextInput
                name="firstName"
                value={values.firstName}
                error={errors.firstName}
                handleChange={handleChange}
                handleBlur={handleBlur}
                label={FIELDS['firstName']}
                disabled={!editMode}
              />
              <TextInput
                name="lastName"
                value={values.lastName}
                error={errors.lastName}
                handleChange={handleChange}
                handleBlur={handleBlur}
                label={FIELDS['lastName']}
                disabled={!editMode}
              />
              <TextInput
                name="email"
                value={values.email}
                error={errors.email}
                handleChange={handleChange}
                handleBlur={handleBlur}
                label={FIELDS['email']}
                disabled={!editMode}
              />
              {editMode && (
                <div className={classes.actionsContainer}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p onClick={handlePasswordReset} className={classes.forgetPassword}>
                      Resetear mi contraseña
                    </p>
                    {submittingPasswordReset && (
                      <CircularProgress color="primary" size={20} style={{ marginLeft: 10 }} />
                    )}
                  </div>
                  <div>
                    {submitting && (
                      <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
                    )}
                    <PrimaryButton
                      type="submit"
                      disabled={loading || submitting}
                      onClick={handleResult}
                      style={{ marginLeft: 10 }}
                    >
                      Confirmar
                    </PrimaryButton>
                  </div>
                </div>
              )}
            </form>
          </div>
        )}
      </Surface>
      <SuccessToast
        open={successMessage !== ''}
        onClose={onCloseToast('successMessage')}
        text={successMessage}
      />
      <ErrorToast
        open={errorMessage !== ''}
        onClose={onCloseToast('errorMessage')}
        text={errorMessage}
      />
    </div>
  );
};

export default withStyles(styles)(ProfileFormComponent);
