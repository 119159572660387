const WEB_REGEX = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export default values => {
  let errors = {};

  if (!values.name) {
    errors.name = 'Complete el nombre comercial';
  }

  if (!values.shortDescription) {
    errors.shortDescription = 'Complete la descripción corta';
  }

  if (!values.longDescription?.getEditorState().getCurrentContent().hasText()) {
    errors.longDescription = 'Complete la descripción larga';
  }

  if (!!values.web && !WEB_REGEX.test(values.web)) {
    errors.web = 'La página web debe ser válida';
  }

  if (
    !values.mainPhoto.imageKey ||
    !values.photos.some(p => p.imageKey === values.mainPhoto.imageKey)
  ) {
    errors.photos = 'Debe seleccionar una imagen principal';
  }

  if (!values.laboratory?._id) {
    errors.laboratory = 'Debe seleccionar un laboratorio';
  }

  return errors;
};
