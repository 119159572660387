import React from 'react';
import { TableContainer } from '../common';

export const MESSAGES = {
  newButton: 'Nueva categoría',
  title: 'Listado de categorías',
  created: 'La categoría ha sido creada correctamente',
  edited: 'La categoría ha sido editada correctamente',
  deleted: 'La categoría ha sido eliminada correctamente',
  deleteConfirmation: i => `¿Estas seguro/a de que queres eliminar la categoría ${i.name}?`,
};

const COLUMNS = [
  {
    id: 'name',
    label: 'Nombre',
    minWidth: 100,
  },
];

export const ENTITY_PROPS = {
  entityName: 'categoría',
  endpoint: 'categories',
  path: '/home/categories',
};

const TreatmentsTable = () => {
  return <TableContainer entityProps={ENTITY_PROPS} messages={MESSAGES} columns={COLUMNS} />;
};

export default TreatmentsTable;
