import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import React from 'react';
import logo from '../../../assets/logo_texto.png';
import { drawerSections } from './options';

const useStyles = makeStyles(theme => ({
  listSubheader: {
    lineHeight: 0,
    paddingBottom: 15,
    fontWeight: 600,
    color: '#9e9c9c',
    fontSize: 12,
  },
  itemText: {
    fontWeight: 600,
    fontSize: 14,
  },
}));

const CustomListItem = ({ focusItem, handleChange, option, value, logout }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <ListItem
      key={option.value}
      button
      style={focusItem(option.value)}
      onClick={() => {
        if (option.path === 'logout') logout();
        else handleChange(option.value);
      }}
      component={Link}
      to={option.path}
    >
      {option.mdi ? (
        <ListItemIcon>
          <Icon
            size={1}
            path={option.icon}
            style={{
              color: value === option.value ? theme.palette.primary.main : 'grey',
              marginLeft: 10,
            }}
          />
        </ListItemIcon>
      ) : (
        <ListItemIcon>
          {React.createElement(option.icon, {
            style: {
              color: value === option.value ? theme.palette.primary.main : 'grey',
              marginLeft: 10,
            },
          })}
        </ListItemIcon>
      )}
      <ListItemText primary={option.label} classes={{ primary: classes.itemText }} />
    </ListItem>
  );
};

const DrawerContent = ({
  smallScreen,
  filteredDrawerOptions,
  value,
  focusItem,
  handleChange,
  logout,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          padding: smallScreen ? 10 : 20,
          textAlign: 'center',
          margin: '20px 0 30px',
        }}
      >
        <img src={logo} width={190} height={undefined} alt="Logo" />
      </div>
      <List>
        {drawerSections.map((section, index) => {
          const sectionOptions = filteredDrawerOptions.filter(op => op.section === section.value);
          return (
            sectionOptions.length > 0 && (
              <div key={section.value}>
                <ListSubheader
                  className={classes.listSubheader}
                  style={{
                    borderTop: index !== 0 ? '1px solid #ececec' : 'unset',
                    marginTop: index !== 0 ? 15 : 0,
                    paddingTop: index !== 0 ? 24 : 0,
                  }}
                >
                  {section.label}
                </ListSubheader>
                {sectionOptions.map(option => (
                  <CustomListItem
                    key={option.value}
                    value={value}
                    option={option}
                    focusItem={focusItem}
                    handleChange={handleChange}
                    logout={logout}
                  />
                ))}
              </div>
            )
          );
        })}
      </List>
      {!smallScreen && (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            flexGrow: 1,
            paddingBottom: 25,
            paddingTop: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              color: 'grey',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography>Powered By</Typography>
            <Typography style={{ fontWeight: 600 }}>DBlandIT</Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default DrawerContent;
