import React, { Component } from 'react';
import { get, patch, handleResponse, swal500, post, getUrl, handle400 } from '../../utils/network';
import ProfileFormComponent from './ProfileFormComponent';
import validateUser from './validator';
import { getCookie } from '../../utils/utils';

export const FIELDS = {
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'Email',
};

export default class ProfileFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        firstName: '',
        lastName: '',
        email: '',
      },
      errors: {},
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
      },
      userId: '',
      imageUrl: '',
      loading: true,
      submittingPasswordReset: false,
      editMode: false,
      successMessage: '',
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
    const userId = getCookie('userId');
    get('users/' + userId)
      .then(res => handleResponse(res, this.props))
      .then(parsed => {
        const { firstName, lastName, email } = parsed.message;
        const newValues = {
          firstName,
          lastName,
          email,
        };
        this.setState({
          values: newValues,
          initialValues: newValues,
          userId,
          imageUrl: getUrl(`users/${userId}/profilepicture`),
          loading: false,
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        swal500(err);
      });
  };

  resetError = name => this.setState({ errors: { ...this.state.errors, [name]: undefined } });

  handleChange = event => {
    const { name, value } = event.target;
    this.resetError(name);
    this.setState({
      values: {
        ...this.state.values,
        [name]: value,
      },
    });
  };

  handleBlur = event => {
    const { name } = event.target;
    const validationErrors = validateUser(this.state.values);
    this.setState({
      errors: { ...this.state.errors, [name]: validationErrors[name] },
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const validationErrors = validateUser(this.state.values);
    this.setState({ errors: validationErrors });
    return Object.entries(validationErrors).length === 0;
  };

  getChangedValues = () => {
    const changedValues = [];
    Object.keys(this.state.initialValues).forEach(key => {
      this.state.initialValues[key] !== this.state.values[key] && changedValues.push(key);
    });
    return changedValues.filter(v => v !== 'created');
  };

  handleResult = e => {
    e.preventDefault();
    const formValid = this.handleSubmit(e);
    if (formValid) {
      this.setState({ submitting: true });
      const body = {};
      const changedValues = this.getChangedValues();
      if (changedValues.length === 0) {
        this.setState({ submitting: false, editMode: false });
        return;
      }
      changedValues.forEach(value => {
        Object.assign(body, { [value]: this.state.values[value] });
      });
      patch(`users/${this.state.userId}`, body)
        .then(res =>
          handleResponse(res, this.props, [
            { status: 400, method: handle400(FIELDS, errors => this.setState({ errors })) },
          ])
        )
        .then(res => {
          this.setState(prevState => ({
            submitting: false,
            editMode: false,
            initialValues: {
              ...prevState.initialValues,
              ...body,
            },
            successMessage: 'Datos actualizados correctamente',
          }));
        })
        .catch(err => {
          this.setState({ submitting: false });
          if (err !== 400) {
            swal500(err);
          }
        });
    }
  };

  onCloseToast = toastKey => () => this.setState({ [toastKey]: '' });

  handlePasswordReset = () => {
    this.setState({ submittingPasswordReset: true });
    post('auth/passwordreset', { email: this.state.initialValues.email })
      .then(res => handleResponse(res, this.props))
      .then(res =>
        this.setState({
          successMessage: 'Te enviamos un mail para resetear tu contraseña.',
          submittingPasswordReset: false,
        })
      )
      .catch(err => {
        swal500(err);
      });
  };

  handleUploadImage = e => {
    const file = e.target.files[0];
    if (file.type.includes('image/')) {
      const newUrl = URL.createObjectURL(file);
      this.setState({ submittingImage: true });
      const formData = new FormData();
      formData.append('profilepicture', file);
      post(
        `users/${this.state.userId}/profilepicture`,
        formData,
        true,
        { Accept: 'Application/json' },
        true
      )
        .then(res => handleResponse(res, this.state))
        .then(res => {
          this.setState({
            submittingImage: false,
            imageUrl: newUrl,
            successMessage: 'Imagen actualizada correctamente',
          });
        })
        .catch(e => {
          this.setState({ submittingImage: false });
          swal500(e);
        });
    } else {
      this.setState({ errorMessage: 'El archivo debe ser de tipo imagen' });
    }
  };

  render() {
    const { loading, submitting, submittingImage, values, errors, editMode, imageUrl } = this.state;
    return (
      <ProfileFormComponent
        errors={errors}
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        handleResult={this.handleResult}
        handleUploadImage={this.handleUploadImage}
        loading={loading}
        submitting={submitting}
        submittingImage={submittingImage}
        values={values}
        editMode={editMode}
        setEditMode={value => this.setState({ editMode: value })}
        imageUrl={imageUrl}
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
        onCloseToast={this.onCloseToast}
        submittingPasswordReset={this.state.submittingPasswordReset}
        handlePasswordReset={this.handlePasswordReset}
      />
    );
  }
}
