import React, { memo } from 'react';
import RichTextEditor from 'react-rte';
import { Typography, useTheme } from '@material-ui/core';

export default memo(({ richText, setRichText, readOnly, error, title }) => {
  const theme = useTheme();
  return (
    <>
      {title && (
        <Typography
          style={{
            margin: '16px 0px 8px',
            fontSize: 16,
          }}
        >
          {title}
        </Typography>
      )}
      <div
        style={{
          border: error && '1px solid ' + theme.palette.error.main,
          borderRadius: '3px',
          marginBottom: 8,
        }}
      >
        <RichTextEditor
          value={richText || RichTextEditor.createEmptyValue()}
          onChange={setRichText}
          toolbarConfig={RichTextEditorToolbarConfig}
          readOnly={readOnly}
          className="text-editor"
        />
      </div>
      {error && (
        <Typography style={{ color: theme.palette.error.main, marginTop: 10, fontSize: 12 }}>
          {error}
        </Typography>
      )}
    </>
  );
});

// Optionally specify the groups to display (displayed in the order listed).
const RichTextEditorToolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS',
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Negrita', style: 'BOLD' },
    { label: 'Cursiva', style: 'ITALIC' },
    { label: 'Subrayar', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Texto normal', style: 'unstyled' },
    { label: 'Título 1', style: 'header-one' },
    { label: 'Título 2', style: 'header-two' },
    { label: 'Título 3', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Lista con viñetas', style: 'unordered-list-item' },
    { label: 'Lista numerada', style: 'ordered-list-item' },
    { label: 'Cita', style: 'blockquote' },
  ],
  HISTORY_BUTTONS: [{ label: 'Deshacer', style: 'undo' }],
};
