import React, { useCallback } from 'react';
import { FormControlLabel, Radio, RadioGroup, Tooltip, withStyles } from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';
import {
  ApiAutocomplete,
  LocaleSelector,
  RichTextEditor,
  TextInput,
  AutocompleteChipSection,
  ImageSelector,
  Title,
} from '../../common';
import styles from './styles';
import { FIELDS } from './DiseasesFormContainer';
import { useHistory } from 'react-router-dom';

const localeSelectorStyles = { margin: '15px 0px 0px' };

const DiseasesFormComponent = ({
  editMode,
  errors,
  handleChange,
  handleBlur,
  classes,
  values,
  hiddenFileInput,
  smallScreen,
  handleUploadImage,
  uploadingImage,
  handleCancel,
  hasChanges,
  deleteImage,
  handleSelectedValue,
}) => {
  const history = useHistory();

  const handleChangeRichText = useCallback(
    value => handleChange({ target: { name: 'description', value } }),
    [handleChange]
  );

  const handleChangeFather = useCallback(
    value => handleChange({ target: { name: 'father', value } }),
    [handleChange]
  );

  const handleChangeDiseaseMaster = useCallback(
    value => handleChange({ target: { name: 'diseaseMaster', value } }),
    [handleChange]
  );

  return (
    <>
      {smallScreen && (
        <LocaleSelector
          setSelectedValue={handleSelectedValue}
          selectedValue={values.locale}
          handleCancel={handleCancel}
          hasChanges={hasChanges()}
          fullWidth
          style={localeSelectorStyles}
        />
      )}
      <TextInput
        name="name"
        value={values.name}
        label={FIELDS['name']}
        error={errors.name}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={!editMode}
      />
      <TextInput
        name="briefDescription"
        value={values.briefDescription}
        label={FIELDS['briefDescription']}
        error={errors.briefDescription}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={!editMode}
      />
      <RichTextEditor
        title="Descripción:"
        richText={values.description}
        setRichText={handleChangeRichText}
        readOnly={!editMode}
        error={editMode && errors.description}
      />
      <AutocompleteChipSection
        editMode={editMode}
        title="Tratamientos"
        field="treatments"
        history={history}
        value={values.treatments}
        handleChange={handleChange}
        noItemsText="No posee tratamientos"
        selectItemText="Seleccione un tratamiento"
        helpText="Si el tratamiento deseado todavía no existe, deberá crearlo y luego agregarlo"
        smallScreen={smallScreen}
      />
      <ApiAutocomplete
        path="diseases-master"
        field="name"
        value={values.diseaseMaster}
        label="Enfermedad del algoritmo"
        handleChange={handleChangeDiseaseMaster}
        error={errors.diseaseMaster}
        showInput={!editMode}
        hide={!editMode && !values.diseaseMaster._id}
      />
      <ImageSelector
        hiddenFileInput={hiddenFileInput}
        deleteImage={deleteImage}
        handleUploadImage={handleUploadImage}
        uploadingImage={uploadingImage}
        photos={values.photos}
        mainPhoto={values.mainPhoto}
        error={errors.photos}
        onFav={i =>
          handleChange({
            target: { name: 'mainPhoto', value: { imageKey: i.imageKey, url: i.url } },
          })
        }
        editMode={editMode}
      />
      {editMode && <Title text="Tipo de patología" />}
      {editMode && (
        <div>
          <RadioGroup
            className={classes.radioGroup}
            value={values.isStage ? 'stage' : 'base'}
            onChange={(e, value) =>
              handleChange({ target: { name: 'isStage', value: value === 'stage' } })
            }
          >
            <FormControlLabel
              value="base"
              control={<Radio color="primary" />}
              label="Patología de base"
            />
            <FormControlLabel
              value="stage"
              control={<Radio color="primary" />}
              label="Estadio/Subtipo"
            />
            {!smallScreen && (
              <Tooltip
                placement="right"
                title="Seleccione Patología de base si la patología posee estadios/subtipos, como por ejemplo Ulcera por presión. Seleccione Estadio/Subtipo en caso de que sea un estadio/subtipo de una patología, como por ejemplo Ulcera por presión estadio II"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            )}
          </RadioGroup>
        </div>
      )}
      {values.isStage && (
        <ApiAutocomplete
          path="diseases"
          field="name"
          label="Patología base"
          value={values.father}
          title="Patología base"
          handleChange={handleChangeFather}
          error={errors.father}
          showInput={!editMode}
          likeLocale
        />
      )}
      {!values.isStage && (
        <AutocompleteChipSection
          title="Estadios/Subtipos"
          editMode={editMode}
          field="stages"
          apiField="diseases"
          history={history}
          value={values.stages}
          handleChange={handleChange}
          noItemsText="No posee estadios/subtipos"
          selectItemText="Seleccione un estadio/subtipo"
          helpText="Si el estadio/subtipo deseado todavía no existe, deberá crearlo y luego agregarloo"
          smallScreen={smallScreen}
        />
      )}
    </>
  );
};

export default withStyles(styles)(DiseasesFormComponent);
