import React, { Component } from 'react';
import { post, handleResponse, swal500, defaultHeaders } from '../../utils/network';
import { getCookie, setCookie } from '../../utils/utils';
import LoginComponent from './LoginComponent';
import { drawerOptions, AllPermissions } from '../../components/common';
import config from '../../utils/config';
import qs from 'qs';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    const params = qs.parse(props.history.location.search, {
      ignoreQueryPrefix: true,
    });
    this.state = {
      username: '',
      password: '',
      loading: false,
      errorusername: '',
      errorpassword: '',
      passwordResetOpen: false,
      successMessage: '',
      token: params.token || null,
      errorLevel: params.level || null,
      errorMessage: params.message || null,
      errorUuid: params.uuid || null,
    };
  }

  errorReset = () => {
    this.setState({
      errorusername: '',
      errorpassword: '',
    });
  };

  emptyInputs = () => {
    const { username, password } = this.state;
    if (username === '') {
      this.setState({ errorusername: 'Complete el nombre de usuario' });
    }
    if (password === '') {
      this.setState({ errorpassword: 'Complete la contraseña' });
    }
    this.setState({ loading: false });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.errorReset();
    this.setState({
      [name]: value,
    });
  };

  handle401 = err => {
    err.json().then(e => {
      if (e.code === 115) {
        this.setState({
          errorMessage: 'Hemos enviado un correo para que valides tu cuenta',
          errorLevel: 'success',
        });
      } else {
        this.setState({
          errorpassword: 'Usuario o contraseña incorrectos',
          errorusername: 'Usuario o contraseña incorrectos',
        });
      }
    });
    return Promise.reject(401);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    if (!(username === '' || password === '')) {
      this.setState({ loading: true }, () => {
        const body = {
          email: username,
          password: password,
          locale: 'es',
        };

        post('auth/login', body, false)
          .then(res => {
            return handleResponse(res, this.props, [
              { status: 401, method: this.handle401 },
              { status: 404, method: () => Promise.reject(404) },
              { status: 400, method: () => Promise.reject(400) },
            ]);
          })
          .then(data => {
            const name = data.message.firstName ? data.message.firstName.split(' ')[0] : 'Usuario';
            this.setState({ loading: false }, () => {
              setCookie('isLogged', true);
              setCookie('userId', data.message.userId);
              setCookie('name', name);
              if (config.devEnvironment) setCookie('token', data.message.token);
              this.redirectUser(data.message.permissions);
            });
          })
          .catch(err => {
            this.setState({ loading: false });
            if (err === 404 || err === 400) {
              this.setState({
                errorpassword: 'Usuario o contraseña incorrectos',
                errorusername: 'Usuario o contraseña incorrectos',
              });
            } else {
              swal500(err);
            }
          });
      });
    } else {
      this.emptyInputs();
    }
  };

  redirectUser = userPermissions => {
    const routeOptions = drawerOptions
      .filter(
        op =>
          op.permissionNeeded === 'none' ||
          userPermissions.some(us_p => us_p === AllPermissions.all) ||
          userPermissions.some(us_p => us_p === AllPermissions[op.permissionNeeded])
      )
      .map(option => ({ path: option.path, value: option.value }));
    setCookie('routeOptions', JSON.stringify(routeOptions));
    this.props.history.push(routeOptions[0].path);
  };

  handlePasswordReset = value => this.setState({ passwordResetOpen: value });

  passwordResetSuccess = () =>
    this.setState({
      successMessage: 'Te enviamos un mail para que puedas recuperar tu contraseña.',
    });

  onCloseToast = toastKey => () => this.setState({ [toastKey]: '' });

  componentDidMount() {
    if (getCookie('isLogged')) {
      this.props.history.push('/home');
    }
    if (this.state.token) {
      post('auth/sso', '', false, defaultHeaders, false, this.state.token)
        .then(res => handleResponse(res, this.props))
        .then(data => {
          const name = data.message.firstName ? data.message.firstName.split(' ')[0] : 'Usuario';
          setCookie('isLogged', true);
          setCookie('userId', data.message.userId);
          setCookie('name', name);
          if (config.devEnvironment) setCookie('token', data.message.token);
          this.redirectUser(data.message.permissions);
        });
    }
  }

  // redirectOauth = endpoint => window.location.replace(`${config.backEndUrl}${endpoint}`);

  render() {
    return (
      <LoginComponent
        successMessage={this.state.successMessage}
        passwordResetOpen={this.state.passwordResetOpen}
        handlePasswordReset={this.handlePasswordReset}
        passwordResetSuccess={this.passwordResetSuccess}
        onCloseToast={this.onCloseToast}
        data={this.state}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        // redirectOauth={this.redirectOauth}
      />
    );
  }
}

export default LoginContainer;
