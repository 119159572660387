import React from 'react';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell as NativeTableCell,
  withStyles,
} from '@material-ui/core';
import { Close as CloseIcon, AddCircle } from '@material-ui/icons';
import { getCountryName } from '../../../utils/utils';
import { SEARCH_TYPES } from './AddRankingDialog';

const TableCell = withStyles(() => ({
  root: {
    padding: 10,
    fontSize: 16,
  },
}))(NativeTableCell);

const RankingTable = ({ ranking, setAddRankingOpen, deleteRanking, editMode, smallScreen }) => {
  return (
    <TableContainer component={Paper} style={{ margin: '2px 0px 8px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">País</TableCell>
            <TableCell align="left">Tipo de búsqueda</TableCell>
            <TableCell align="left">Valor de búsqueda</TableCell>
            <TableCell align="left">Posición</TableCell>
            <TableCell align="right">
              {editMode ? (
                smallScreen ? (
                  <IconButton
                    style={{ padding: 0, marginLeft: 5 }}
                    onClick={() => setAddRankingOpen(true)}
                  >
                    <AddCircle color="primary" />
                  </IconButton>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setAddRankingOpen(true)}
                  >
                    Agregar nueva fila
                  </Button>
                )
              ) : (
                <div style={{ height: '100%', width: '100%' }} />
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ranking.map((r, i) => (
            <TableRow key={i}>
              <TableCell align="left">{getCountryName(r.country)}</TableCell>
              <TableCell align="left">{SEARCH_TYPES[r.searchType].name}</TableCell>
              <TableCell align="left">{r.key.name}</TableCell>
              <TableCell align="left">{r.position}</TableCell>
              <TableCell align="right">
                {editMode ? (
                  <IconButton onClick={() => deleteRanking(i)} style={{ padding: 10 }}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <div style={{ height: '100%', width: '100%' }} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RankingTable;
