import * as iso3166 from 'iso-3166-2';

export const sleep = (timeMS, onTimer) =>
  new Promise((resolve, reject) => setTimeout(() => onTimer(resolve, reject), timeMS));

export const deleteCookie = name => {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
};

export const getCookie = name => {
  let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};

export const setCookie = (name, value) => {
  document.cookie = `${name}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
};

export const isLogged = () => getCookie('isLogged');
export const logout = () => deleteCookie('isLogged');

export const countries = Object.values(iso3166.data).map(country => country.name);

export const getCountrySubs = name =>
  Object.entries(iso3166.country(name).sub).map(v => ({
    name: v[1].name,
    code: v[0],
  }));

export const getCountryCode = name => Object.entries(iso3166.country(name).sub)[0][0];

export const getCountryName = code => code && iso3166.country(code.split('-')[0]).name;

export const getRegionName = code => code && iso3166.country(code.split('-')[0]).sub[code].name;

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

export const DEFAULT_LOCALE = {
  language: 'Español',
  locale: 'es',
};

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
