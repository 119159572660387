import React, { useCallback } from 'react';
import { CircularProgress, IconButton, withStyles } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import {
  ProductCard,
  ApiAutocompleteAdd,
  LocaleSelector,
  RichTextEditor,
  TextInput,
  TitleNoItems,
} from '../../common';
import styles from './styles';
import { FIELDS } from './TreatmentsFormContainer';

const localeSelectorStyle = { margin: '15px 0px 0px' };

const TreatmentsFormComponent = ({
  editMode,
  errors,
  handleChange,
  handleBlur,
  classes,
  values,
  smallScreen,
  hasChanges,
  handleCancel,
  loadingProducts,
  getMoreProducts,
  loadingMoreProducts,
  noMoreProducts,
  isNew,
  handleSelectedValue,
}) => {
  const handleChangeRichText = useCallback(
    value => handleChange({ target: { name: 'description', value } }),
    [handleChange]
  );

  const handleAddProduct = useCallback(
    newValue =>
      handleChange({ target: { name: 'products', value: [...values.products, newValue] } }),
    [handleChange, values.products]
  );

  return (
    <>
      {smallScreen && (
        <LocaleSelector
          fullWidth
          style={localeSelectorStyle}
          setSelectedValue={handleSelectedValue}
          selectedValue={values.locale}
          hasChanges={hasChanges()}
          handleCancel={handleCancel}
        />
      )}
      <TextInput
        name="name"
        value={values.name}
        error={errors.name}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['name']}
        disabled={!editMode}
      />
      {(editMode || values.description?.getEditorState().getCurrentContent().hasText()) && (
        <RichTextEditor
          title="Descripción:"
          richText={values.description}
          setRichText={handleChangeRichText}
          readOnly={!editMode}
        />
      )}
      <TitleNoItems
        title="Productos"
        text="El tratamiento no posee productos"
        noItems={!editMode && !loadingProducts && values.products.length === 0}
      />
      {editMode && (
        <ApiAutocompleteAdd
          path="products"
          field="name"
          label="Producto"
          title={smallScreen ? 'Seleccione un producto' : 'Seleccione un producto para agregar'}
          helpText="Si el producto deseado todavía no existe, deberá crearlo y luego agregarlo"
          smallScreen={smallScreen}
          filterIds={values.products.map(p => p._id)}
          onAdd={handleAddProduct}
        />
      )}
      {loadingProducts ? (
        <CircularProgress size={30} style={{ marginBottom: 10, alignSelf: 'center' }} />
      ) : (
        values.products?.length > 0 && (
          <div className={classes.imageContainer}>
            {values.products.map((p, index) => (
              <ProductCard
                key={index}
                item={p}
                editMode={editMode}
                onDelete={() =>
                  handleChange({
                    target: {
                      name: 'products',
                      value: values.products.filter(pr => pr._id !== p._id),
                    },
                  })
                }
              />
            ))}
            {!noMoreProducts && !isNew && (
              <div
                style={{
                  margin: 10,
                  width: 220,
                  height: 90,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {loadingMoreProducts ? (
                  <CircularProgress size={30} style={{ marginBottom: 10, alignSelf: 'center' }} />
                ) : (
                  <IconButton style={{ flex: 1, borderRadius: 0 }} onClick={getMoreProducts}>
                    <AddIcon />
                  </IconButton>
                )}
              </div>
            )}
          </div>
        )
      )}
    </>
  );
};

export default withStyles(styles)(TreatmentsFormComponent);
