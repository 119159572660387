import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import logo from '../../assets/logo.png';

const VerifiedAccount = ({ classes }) => (
  <div className={classes.background}>
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.gridLogin}
    >
      <form className={classes.formLogin} onSubmit={() => {}}>
        <img src={logo} width={200} alt="Logo" style={{ margin: '0 auto', paddingBottom: 10 }} />
        <p style={{ color: '#2e2e2e', marginTop: 30, lineHeight: 1.5 }}>
          Su cuenta ha sido verificada exitosamente. <br /> ¡Ya puede comenzar a utilizar Curapp!
        </p>
      </form>
    </Grid>
  </div>
);

export default withStyles(styles)(VerifiedAccount);
