import React from 'react';
import { TableContainer } from '../common';

export const MESSAGES = {
  title: 'Listado de principios activos',
  created: 'El principio activo ha sido creado correctamente',
  edited: 'El principio activo ha sido editado correctamente',
  deleted: 'El principio activo ha sido eliminado correctamente',
  deleteConfirmation: generic =>
    `¿Estas seguro/a de que queres eliminar el principio activo ${generic.name}?`,
};

const COLUMNS = [
  {
    id: 'name',
    label: 'Nombre',
    minWidth: 100,
  },
];

export const ENTITY_PROPS = {
  entityName: 'principio activo',
  endpoint: 'generics',
  path: '/home/generics',
};

const TreatmentsTable = () => {
  return <TableContainer entityProps={ENTITY_PROPS} messages={MESSAGES} columns={COLUMNS} />;
};

export default TreatmentsTable;
