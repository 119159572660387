import { useCallback, useState } from 'react';

const usePermissionsSelector = () => {
  const [permissions, setPermissions] = useState([]);

  const checkPermission = useCallback((permissions, name, checked) => {
    return permissions.map(permission => {
      if (permission.name === name) {
        return { ...permission, checked: checked };
      }
      return permission;
    });
  }, []);

  const handleChangePermission = useCallback(
    (categoryName, e) => {
      const { name, checked } = e.target;
      setPermissions(prevState =>
        prevState.map(category => {
          if (category.category === categoryName) {
            return {
              ...category,
              permissions: checkPermission(category.permissions, name, checked),
            };
          }
          return category;
        })
      );
    },
    [checkPermission]
  );

  const handleChangeCategory = useCallback((categoryName, e) => {
    setPermissions(prevState =>
      prevState.map(category => {
        if (category.category === categoryName) {
          return {
            ...category,
            permissions: category.permissions.map(p => ({
              ...p,
              checked: !e.target.checked && p.disabled ? true : e.target.checked, //if permission is disabled, cant uncheck
            })),
          };
        }
        return category;
      })
    );
  }, []);

  const getPermissionsList = permissions
    .flatMap(category => category.permissions)
    .filter(permission => permission.checked && !permission.disabled)
    .map(permission => permission._id);

  const permissionsDidChange = oldPermissions => {
    let didChange = false;
    if (getPermissionsList?.length !== oldPermissions?.length) didChange = true;
    else {
      getPermissionsList.forEach(permission => {
        if (!oldPermissions.includes(permission)) didChange = true;
      });
    }
    return didChange;
  };

  return {
    handleChangePermission,
    handleChangeCategory,
    permissions,
    setPermissions,
    getPermissionsList,
    permissionsDidChange,
  };
};

export default usePermissionsSelector;
