import React from 'react';
import { TableContainer } from '../common';

export const MESSAGES = {
  newButton: 'Nuevo producto',
  title: 'Listado de productos',
  created: 'El producto ha sido creado correctamente',
  edited: 'El producto ha sido editado correctamente',
  deleted: 'El producto ha sido eliminado correctamente',
  deleteConfirmation: item => `¿Estas seguro/a de que queres eliminar el producto ${item.name}?`,
};

const COLUMNS = [
  {
    id: 'name',
    label: 'Nombre',
    minWidth: 100,
  },
  {
    id: 'laboratory',
    field: 'name',
    label: 'Laboratorio',
    minWidth: 100,
    hidden: {
      mdDown: true,
    },
  },
];

export const ENTITY_PROPS = {
  entityName: 'producto',
  endpoint: 'products',
  path: '/home/products',
};

const ProductsTable = () => {
  return <TableContainer entityProps={ENTITY_PROPS} messages={MESSAGES} columns={COLUMNS} />;
};

export default ProductsTable;
