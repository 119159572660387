import React, { useCallback, useState } from 'react';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import CountryAutocomplete from './CountryAutocomplete';

function CountryAutocompleteAdd({
  smallScreen,
  onAdd,
  label = 'Seleccione un país',
  filterCountries,
}) {
  const [value, setValue] = useState({});
  const [resetValue, setResetValue] = useState(false);
  const handleAdd = () => {
    if (value.code) {
      onAdd(value);
      setResetValue(r => !r);
      setValue('');
    }
  };
  const handleChange = useCallback(value => setValue(value), [setValue]);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <CountryAutocomplete
        label={label}
        value={value.name}
        showInput={false}
        dependsOn={resetValue}
        handleChange={handleChange}
        filterCountries={filterCountries}
        compact
      />
      {smallScreen ? (
        <IconButton style={{ padding: 0 }} onClick={handleAdd}>
          <AddCircle color="primary" />
        </IconButton>
      ) : (
        <Button
          onClick={handleAdd}
          color="primary"
          variant="contained"
          style={{ padding: '2px 7px', marginLeft: 10 }}
        >
          Agregar
        </Button>
      )}
      {!smallScreen && <div style={{ width: 25 }} />}
    </div>
  );
}

export default CountryAutocompleteAdd;
