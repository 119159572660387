import { Group, Person, Phonelink, Security, ExitToApp } from '@material-ui/icons';
import {
  mdiFlaskOutline,
  mdiClipboardPulseOutline,
  mdiLotionOutline,
  mdiNeedle,
  mdiPill,
  mdiLayers,
} from '@mdi/js';

const drawerOptions = [
  {
    label: 'Usuarios',
    value: 0,
    path: '/home/users',
    icon: Group,
    permissionNeeded: 'viewUsers',
    section: 1,
  },
  {
    label: 'Roles',
    value: 1,
    path: '/home/roles',
    icon: Security,
    permissionNeeded: 'viewRoles',
    section: 1,
  },
  {
    label: 'Perfil',
    value: 2,
    path: '/home/profile',
    icon: Person,
    permissionNeeded: 'none',
    section: 0,
  },
  {
    label: 'Sesiones',
    value: 3,
    path: '/home/sessions',
    icon: Phonelink,
    permissionNeeded: 'none',
    section: 0,
  },
  {
    label: 'Patologías',
    value: 4,
    path: '/home/diseases',
    icon: mdiClipboardPulseOutline,
    mdi: true,
    permissionNeeded: 'manageDiseases',
    section: 1,
  },
  {
    label: 'Tratamientos',
    value: 5,
    path: '/home/treatments',
    icon: mdiNeedle,
    mdi: true,
    permissionNeeded: 'manageTreatments',
    section: 1,
  },
  {
    label: 'Cerrar sesión',
    value: 6,
    path: 'logout',
    icon: ExitToApp,
    permissionNeeded: 'none',
    section: 0,
  },
  {
    label: 'Productos',
    value: 7,
    path: '/home/products',
    icon: mdiLotionOutline,
    mdi: true,
    permissionNeeded: 'manageProducts',
    section: 1,
  },
  {
    label: 'Funciones',
    value: 8,
    path: '/home/drug-functions',
    icon: mdiPill,
    mdi: true,
    permissionNeeded: 'manageDrugFunctions',
    section: 1,
  },
  {
    label: 'Principios activos',
    value: 9,
    path: '/home/generics',
    icon: mdiFlaskOutline,
    mdi: true,
    permissionNeeded: 'manageGenerics',
    section: 1,
  },
  {
    label: 'Categorías',
    value: 10,
    path: '/home/categories',
    icon: mdiLayers,
    mdi: true,
    permissionNeeded: 'manageCategories',
    section: 1,
  },
];

const drawerSections = [
  {
    value: 0,
    label: 'Mi cuenta',
  },
  {
    value: 1,
    label: 'Administración',
  },
];

export { drawerOptions, drawerSections };
