export default () => ({
  sectionTitle: {
    fontWeight: 600,
    color: 'grey',
    margin: 0,
    fontSize: 16,
    marginTop: 21,
    marginBottom: 8,
  },
});
