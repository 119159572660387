import React, { useCallback, useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import isEqualWith from 'lodash/isEqualWith';
import TreatmentsFormComponent from './TreatmentsFormComponent';
import { get, handleResponse, patch, post, swal500, handle400 } from '../../../utils/network';
import { FormLayout, LocaleSelector, useFormContainer, useFormValidation } from '../../common';
import validate from './validator';
import { DEFAULT_LOCALE } from '../../../utils/utils';
import { ENTITY_PROPS, MESSAGES } from '../TreatmentsTable';

export const FIELDS = {
  name: 'Nombre del tratamiento',
  description: 'Descripción',
  products: 'Productos',
};

const INITIAL_STATE = {
  name: '',
  description: null,
  products: [],
  locale: DEFAULT_LOCALE,
};

const TreatmentsFormContainer = ({ history, match }) => {
  const { id } = match.params;
  const { entityName, endpoint, path } = ENTITY_PROPS;
  const [loadingProducts, setLoadingProducts] = useState(id !== 'new');
  const [loadingMoreProducts, setLoadingMoreProducts] = useState(false);
  const [productsNextPage, setProductsNextPage] = useState(0);
  const [noMoreProducts, setNoMoreProducts] = useState(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    setErrors,
    resetValues,
  } = useFormValidation(INITIAL_STATE, values => validate(values));

  const {
    data,
    setData,
    editMode,
    setEditMode,
    loading,
    setLoading,
    submitting,
    setSubmitting,
    isNew,
    handleClose,
    handleCancel,
    handleDelete,
    smallScreen,
  } = useFormContainer(ENTITY_PROPS, resetValues, MESSAGES.deleteConfirmation(values));

  const customizer = (prevValue, currentValue) => {
    if (prevValue?._editorState && currentValue?._editorState)
      return prevValue.toString('markdown') === currentValue.toString('markdown');
  };

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(data).forEach(key => {
      if (!isEqualWith(data[key], values[key], customizer)) changedValues.push(key);
    });
    return changedValues;
  };

  const getMoreProducts = () => {
    setLoadingMoreProducts(true);
    get(`products?treatments=in:${id}&page=${productsNextPage}`)
      .then(res => handleResponse(res, { history }))
      .then(parsed => {
        setData(prev => ({ ...prev, products: [...prev.products, ...parsed.message.data] }));
        if (parsed.message.data.length < 10 || (productsNextPage + 1) * 10 >= parsed.message.count)
          setNoMoreProducts(true);
        setProductsNextPage(n => n + 1);
        handleChange({
          target: {
            name: 'products',
            value: [...values.products, ...parsed.message.data],
          },
        });
      })
      .catch(err => {
        if (err !== 403) {
          swal500(err);
        }
      })
      .finally(() => {
        setLoadingProducts(false);
        setLoadingMoreProducts(false);
      });
  };

  useEffect(() => {
    const getProducts = () => {
      setLoadingProducts(true);
      get(`products?treatments=in:${id}&page=0`) //&locale=${values.locale.locale}
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          setData(prev => ({ ...prev, products: parsed.message.data }));
          if (parsed.message.data.length < 10 || parsed.message.count <= 10)
            setNoMoreProducts(true);
          else setNoMoreProducts(false);
          setProductsNextPage(1);
          handleChange({
            target: {
              name: 'products',
              value: parsed.message.data,
            },
          });
        })
        .catch(err => swal500(err))
        .finally(() => setLoadingProducts(false));
    };
    const getTreatments = () => {
      setLoading(true);
      get(`${endpoint}/${id}?locale=${values.locale.locale}`)
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          const newData = {
            ...INITIAL_STATE,
            ...parsed.message,
            locale: values.locale,
            description: parsed.message.description
              ? RichTextEditor.createValueFromString(parsed.message.description, 'markdown')
              : RichTextEditor.createEmptyValue(),
          };
          setData(newData);
          resetValues(newData);
          getProducts();
          setLoading(false);
        })
        .catch(err => {
          swal500(err);
          history.push(path);
        });
    };

    if (id === 'new') {
      setData(INITIAL_STATE);
      setEditMode(true);
      setLoading(false);
    } else {
      getTreatments();
    }
  }, [
    id,
    resetValues,
    setData,
    setEditMode,
    setLoading,
    history,
    endpoint,
    path,
    values.locale,
    handleChange,
  ]);

  const hasChanges = () => {
    const changedValues = getChangedValues(values);
    return (
      editMode &&
      (changedValues.length > 1 || (changedValues.length === 1 && changedValues[0] !== 'locale'))
    );
  };

  const handleResult = e => {
    const formValid = handleSubmit(e);
    if (formValid) {
      setSubmitting(true);
      const { name, description, products, locale } = values;
      const hasDescription = description?.getEditorState().getCurrentContent().hasText();
      if (isNew) {
        const body = {
          name,
          description: hasDescription ? description?.toString('markdown') : undefined,
          newProducts: products.map(p => p._id),
          hasNoProducts: !hasDescription && products.length === 0,
          locale: locale.locale,
        };
        post(endpoint, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'created' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      } else {
        let body = { locale: locale.locale };
        const changedValues = getChangedValues(values);
        if (changedValues.length === 0) {
          history.push(path);
          return;
        }
        changedValues.forEach(value => {
          if (value === 'description')
            body = {
              ...body,
              description: hasDescription ? description?.toString('markdown') : null,
            };
          else if (value === 'products') {
            const finalProducts = products.map(p => p._id);
            const initialProducts = data.products.map(p => p._id);
            const newProducts = finalProducts.filter(p => !initialProducts.includes(p));
            const removeProducts = initialProducts.filter(p => !finalProducts.includes(p));
            body = { ...body, newProducts, removeProducts };
          } else body = { ...body, [value]: values[value] };
        });
        body = { ...body, hasNoProducts: !hasDescription && products.length === 0 };
        patch(`${endpoint}/${id}`, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'edited' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      }
    }
  };

  const handleSelectedValue = useCallback(
    value => handleChange({ target: { name: 'locale', value } }),
    [handleChange]
  );

  const renderMoreActions = () =>
    !smallScreen && (
      <LocaleSelector
        setSelectedValue={handleSelectedValue}
        selectedValue={values.locale}
        hasChanges={hasChanges()}
        handleCancel={handleCancel}
      />
    );

  return (
    <FormLayout
      handleClose={handleClose}
      renderMoreActions={renderMoreActions}
      loading={loading}
      editMode={editMode}
      submitting={submitting}
      handleSubmit={handleResult}
      isNew={isNew}
      entityName={entityName}
      handleCancel={handleCancel}
      hasChanges={hasChanges}
      handleDelete={handleDelete}
      handleEdit={() => setEditMode(true)}
    >
      <TreatmentsFormComponent
        editMode={editMode}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        smallScreen={smallScreen}
        handleCancel={handleCancel}
        hasChanges={hasChanges}
        loadingProducts={loadingProducts}
        getMoreProducts={getMoreProducts}
        loadingMoreProducts={loadingMoreProducts}
        noMoreProducts={noMoreProducts}
        isNew={id === 'new'}
        handleSelectedValue={handleSelectedValue}
      />
    </FormLayout>
  );
};

export default TreatmentsFormContainer;
