import React, { memo, useCallback, useMemo } from 'react';
import { Chip } from '@material-ui/core';
import ApiAutocompleteAdd from './ApiAutocompleteAdd';
import { TitleNoItems } from '../Title';

function AutocompleteChipSection({
  editMode,
  title,
  field,
  apiField = field,
  idField = '_id',
  history,
  value,
  handleChange,
  likeLocale,
  noItemsText,
  selectItemText,
  helpText,
  smallScreen,
}) {
  const handleAdd = useCallback(
    newValue =>
      handleChange({
        target: {
          name: field,
          value: [...value, newValue],
        },
      }),
    [handleChange, value, field]
  );
  const filterIds = useMemo(() => value.map(t => t[idField]), [value, idField]);
  const noItems = useMemo(() => !editMode && value.length === 0, [value, editMode]);
  return (
    <div>
      <TitleNoItems title={title} text={noItemsText} noItems={noItems} />
      {editMode && (
        <ApiAutocompleteAdd
          path={apiField}
          field="name"
          likeLocale={likeLocale}
          history={history}
          title={selectItemText + (smallScreen ? '' : ' para agregar')}
          helpText={helpText}
          smallScreen={smallScreen}
          filterIds={filterIds}
          onAdd={handleAdd}
        />
      )}
      {value.length > 0 && (
        <div style={{ marginBottom: 8 }}>
          {value.map((i, index) => (
            <Chip
              key={index}
              style={{ marginTop: 10, marginRight: 10 }}
              label={i.name}
              size="small"
              color="primary"
              variant="outlined"
              onDelete={
                editMode
                  ? () =>
                      handleChange({
                        target: {
                          name: field,
                          value: value.filter(item => item[idField] !== i[idField]),
                        },
                      })
                  : undefined
              }
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(AutocompleteChipSection);
