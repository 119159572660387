import React, { useCallback, useEffect } from 'react';
import { get, handleResponse, patch, post, swal500, handle400 } from '../../../utils/network';
import {
  FormLayout,
  TextInput,
  useFormContainer,
  useFormValidation,
  useGetPermissions,
  usePermissionSelector,
  PermissionsSelector,
} from '../../common';
import validateRole from './validator';
import { ENTITY_PROPS, MESSAGES } from '../RolesTable';

const FIELDS = {
  role: 'Nombre del rol',
};

const INITIAL_STATE = {
  role: '',
  permissions: [],
};

const RolesForm = ({ match, history }) => {
  const { id } = match.params;
  const { entityName, endpoint, path } = ENTITY_PROPS;

  const { handleSubmit, handleChange, handleBlur, values, errors, setErrors, resetValues } =
    useFormValidation(INITIAL_STATE, values => validateRole(values));

  const {
    data,
    setData,
    editMode,
    setEditMode,
    loading,
    setLoading,
    submitting,
    setSubmitting,
    isNew,
    handleClose,
    handleCancel,
    handleDelete,
  } = useFormContainer(ENTITY_PROPS, resetValues, MESSAGES.deleteConfirmation(values));

  const {
    handleChangePermission,
    handleChangeCategory,
    permissions,
    setPermissions,
    getPermissionsList,
    permissionsDidChange,
  } = usePermissionSelector();

  const permissionMapper = useCallback(
    permission => ({
      ...permission,
      checked: data?.permissions?.map(p => p._id).includes(permission._id) || false,
      disabled: !editMode,
    }),
    [data, editMode]
  );

  const { loadingPermissions, setLoadingPermissions, setAllPermissions, getPermissions } =
    useGetPermissions(permissionMapper, setPermissions);

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(data).forEach(key => data[key] !== values[key] && changedValues.push(key));
    if (permissionsDidChange(data.permissions.map(p => p._id))) changedValues.push('permissions');
    return changedValues;
  };

  useEffect(() => {
    const getRole = () => {
      setLoading(true);
      get(`${endpoint}/${id}`)
        .then(res => handleResponse(res, { history }))
        .then(resRole => {
          setLoadingPermissions(true);
          get('permissions')
            .then(res => handleResponse(res, { history }))
            .then(resPermission => {
              setData(resRole.message);
              setAllPermissions(resPermission.message);
              resetValues(resRole.message);
            })
            .catch(e => {
              swal500(e);
              history.push(path);
            })
            .finally(() => {
              setLoadingPermissions(false);
              setLoading(false);
            });
        })
        .catch(err => {
          swal500(err);
          history.push(path);
        });
    };

    if (id === 'new') {
      setData(INITIAL_STATE);
      setEditMode(true);
      getPermissions();
      setLoading(false);
    } else {
      getRole();
    }
  }, [
    endpoint,
    getPermissions,
    history,
    id,
    path,
    resetValues,
    setAllPermissions,
    setData,
    setEditMode,
    setLoading,
    setLoadingPermissions,
  ]);

  const hasChanges = () => editMode && getChangedValues(values).length > 0;

  const handleResult = e => {
    const formValid = handleSubmit(e);
    if (formValid) {
      setSubmitting(true);
      if (isNew) {
        post(endpoint, {
          role: values.role,
          permissions: getPermissionsList,
        })
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'created' } }))
          .catch(err => err !== 400 && swal500(err))
          .finally(() => setSubmitting(false));
      } else {
        let body = {};
        const changedValues = getChangedValues(values);
        if (changedValues.length === 0) {
          history.push(path);
          return;
        }
        changedValues.forEach(value => {
          body =
            value === 'permissions'
              ? { ...body, permissions: getPermissionsList }
              : { ...body, [value]: values[value] };
        });
        patch(`${endpoint}/${id}`, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'edited' } }))
          .catch(err => err !== 400 && swal500(err))
          .finally(() => setSubmitting(false));
      }
    }
  };

  return (
    <FormLayout
      handleClose={handleClose}
      loading={loading}
      editMode={editMode}
      submitting={submitting}
      handleSubmit={handleResult}
      handleCancel={handleCancel}
      handleEdit={() => setEditMode(true)}
      handleDelete={handleDelete}
      isNew={isNew}
      entityName={entityName}
      hasChanges={hasChanges}
    >
      <TextInput
        name="role"
        error={errors.role}
        value={values.role}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['role']}
        disabled={!editMode}
      />
      <PermissionsSelector
        permissions={permissions}
        editMode={editMode}
        loading={loadingPermissions}
        handleChangePermission={handleChangePermission}
        handleChangeCategory={handleChangeCategory}
      />
    </FormLayout>
  );
};

export default RolesForm;
