import React, { useEffect, useState, memo } from 'react';
import { CircularProgress, InputAdornment, TextField, useTheme } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';
import { Autocomplete } from '@material-ui/lab';
import ISO6391 from 'iso-639-1';
import Swal from 'sweetalert2';
import { get, handleResponse } from '../../utils/network';

//TODO: Al usar startAdornment, el label del TextInput queda siempre achicado arriba
//https://github.com/mui-org/material-ui/issues/13898
function LocaleSelector({
  style = {},
  fullWidth,
  selectedValue,
  setSelectedValue,
  onChange,
  handleCancel,
  hasChanges,
}) {
  const theme = useTheme();
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(true);
  const [locales, setLocales] = useState([]);

  const getLocales = () =>
    get('locales')
      .then(handleResponse)
      .then(res => {
        const rawLocales = [...res.message.data];
        const newLocales = rawLocales.map(locale => {
          const [languageCode, countryCode] = locale.locale.split('-');
          return {
            language: ISO6391.getNativeName(languageCode),
            country: locale.name.split(' - ')[1],
            countryCode,
            locale: locale.locale,
          };
        });
        setLocales(newLocales);
        setLoading(false);
      });

  const getFlagCountryCode = locale => {
    if (loading) return;
    if (!locale.countryCode) {
      if (locale.locale === 'es') return 'ES';
      if (locale.locale === 'en') return 'GB';
    } else return locale.countryCode;
  };

  const onChangeLocale = (event, value) => {
    if (value) {
      if (!hasChanges) {
        onChange && onChange(value);
        setSelectedValue(value);
        document.activeElement.blur();
      } else
        Swal.fire({
          title: 'Descartar cambios',
          text: 'Si continúa perderá todos los cambios',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: theme.palette.primary.main,
          cancelButtonText: 'No, conservar cambios',
          confirmButtonText: 'Descartar cambios',
          preConfirm: () => {
            handleCancel(true);
            onChange && onChange(value);
            setSelectedValue(value);
          },
          onAfterClose: () => document.activeElement.blur(),
        });
    }
  };

  useEffect(() => {
    getLocales();
  }, []);

  return (
    <Autocomplete
      options={locales}
      style={{ display: 'flex', alignItems: 'center', marginRight: 20, ...style }}
      onChange={onChangeLocale}
      value={!loading && selectedValue}
      onInputChange={(event, text) => setText(text || '')}
      getOptionSelected={(option, value) => option.locale === value.locale}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <ReactCountryFlag
                  svg
                  style={{
                    width: '2em',
                    height: '2em',
                    marginRight: 5,
                  }}
                  countryCode={getFlagCountryCode(selectedValue)}
                  title={getFlagCountryCode(selectedValue)}
                />
              </InputAdornment>
            ),
            endAdornment: loading ? <CircularProgress size={20} /> : params.InputProps.endAdornment,
            style: { padding: '5px 60px 1.5px 10px', width: !fullWidth && 300 },
          }}
          variant="outlined"
          label="Seleccione un idioma"
          value={text}
        />
      )}
      getOptionLabel={option =>
        loading
          ? 'Cargando...'
          : option.country
          ? `${option.language} - ${option.country}`
          : option.language
      }
      renderOption={option => (
        <React.Fragment>
          <ReactCountryFlag
            svg
            style={{
              width: '2em',
              height: '2em',
              marginRight: 10,
            }}
            countryCode={getFlagCountryCode(option)}
            title={getFlagCountryCode(option)}
          />
          {option.country ? `${option.language} - ${option.country}` : option.language}
        </React.Fragment>
      )}
    />
  );
}

export default memo(LocaleSelector);
