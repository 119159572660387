import React, { useCallback, useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import isEqualWith from 'lodash/isEqualWith';
import {
  get,
  handleResponse,
  patch,
  post,
  swal500,
  handle400,
  putS3,
} from '../../../utils/network';
import validate from './validator';
import DiseasesFormComponent from './DiseasesFormComponent';
import { FormLayout, LocaleSelector, useFormContainer, useFormValidation } from '../../common';
import { DEFAULT_LOCALE } from '../../../utils/utils';
import { ENTITY_PROPS, MESSAGES } from '../DiseasesTable';

export const FIELDS = {
  name: 'Nombre de la patología',
  briefDescription: 'Descripción corta',
  description: 'Descripción',
  treatments: 'Tratamientos',
  mainPhoto: 'Imagen principal',
  photos: 'Imagenes',
};

const INITIAL_STATE = {
  name: '',
  description: null,
  briefDescription: '',
  treatments: [],
  photos: [],
  mainPhoto: {},
  isStage: false,
  stages: [],
  father: {},
  diseaseMaster: {},
  locale: DEFAULT_LOCALE,
};

const DiseasesFormContainer = ({ history, match }) => {
  const { id } = match.params;
  const { entityName, endpoint, path } = ENTITY_PROPS;

  const [uploadingImage, setUploadingImage] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    setErrors,
    resetValues,
  } = useFormValidation(INITIAL_STATE, values => validate(values));

  const {
    data,
    setData,
    editMode,
    setEditMode,
    loading,
    setLoading,
    submitting,
    setSubmitting,
    isNew,
    handleClose,
    handleCancel,
    handleDelete,
    smallScreen,
  } = useFormContainer(ENTITY_PROPS, resetValues, MESSAGES.deleteConfirmation(values));

  const customizer = (prevValue, currentValue) => {
    if (prevValue?._editorState && currentValue?._editorState)
      return prevValue?.toString('markdown') === currentValue.toString('markdown');
  };

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(data).forEach(key => {
      if (!isEqualWith(data[key], values[key], customizer)) changedValues.push(key);
    });
    return changedValues;
  };

  useEffect(() => {
    const getDiseaseMaster = data => {
      get(`diseases-master?disease=${id}`)
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          const newData = { ...data, diseaseMaster: parsed.message.data[0] || {} };
          setData(newData);
          resetValues(newData);
          setLoading(false);
        })
        .catch(err => {
          if (err !== 403) swal500(err);
          history.push(path);
        });
    };
    const getDisease = () => {
      setLoading(true);
      get(`${endpoint}/${id}?locale=${values.locale.locale}`)
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          const newData = {
            ...INITIAL_STATE,
            ...parsed.message.data,
            locale: values.locale,
            isStage: !!parsed.message.data.father?.name,
            description: parsed.message.data.description
              ? RichTextEditor.createValueFromString(parsed.message.data.description, 'markdown')
              : RichTextEditor.createEmptyValue(),
          };
          getDiseaseMaster(newData);
        })
        .catch(err => {
          if (err !== 403) swal500(err);
          history.push(path);
        });
    };

    if (id === 'new') {
      setData(INITIAL_STATE);
      setEditMode(true);
      setLoading(false);
    } else {
      getDisease();
    }
  }, [id, resetValues, setData, setEditMode, setLoading, history, endpoint, path, values.locale]);

  const hasChanges = () => {
    const changedValues = getChangedValues(values);
    return (
      editMode &&
      (changedValues.length > 1 || (changedValues.length === 1 && changedValues[0] !== 'locale'))
    );
  };

  const handleResult = e => {
    const formValid = handleSubmit(e);
    if (formValid) {
      setSubmitting(true);
      const {
        name,
        description,
        briefDescription,
        photos,
        mainPhoto,
        stages,
        father,
        treatments,
        diseaseMaster,
        isStage,
        locale,
      } = values;
      const hasDescription = description?.getEditorState().getCurrentContent().hasText();
      if (isNew) {
        const body = {
          name,
          description: hasDescription ? description.toString('markdown') : undefined,
          briefDescription,
          photos: photos.map(p => p.imageKey),
          mainPhoto: mainPhoto.imageKey,
          isStage,
          stages: !isStage ? stages.map(s => s._id) : undefined,
          father: isStage ? father._id : undefined,
          treatments: treatments.map(t => t._id),
          diseaseMaster: diseaseMaster._id,
          locale: locale.locale,
        };
        post(endpoint, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'created' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      } else {
        let body = { locale: locale.locale };
        const changedValues = getChangedValues(values);
        if (changedValues.length === 0) {
          history.push(path);
          return;
        }
        changedValues.forEach(value => {
          if (value === 'mainPhoto') body = { ...body, mainPhoto: mainPhoto.imageKey };
          else if (value === 'photos') body = { ...body, photos: photos.map(p => p.imageKey) };
          else if (value === 'treatments')
            body = { ...body, treatments: treatments.map(t => t._id) };
          else if (value === 'stages' || value === 'father' || value === 'isStage')
            body = {
              ...body,
              stages: !isStage ? stages.map(s => s._id) : undefined,
              father: isStage ? father._id : undefined,
              isStage,
            };
          else if (value === 'description')
            body = {
              ...body,
              description: hasDescription ? description.toString('markdown') : null,
            };
          else if (value === 'diseaseMaster')
            body = { ...body, diseaseMaster: diseaseMaster._id ? diseaseMaster._id : null };
          else body = { ...body, [value]: values[value] };
        });
        patch(`${endpoint}/${id}`, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'edited' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      }
    }
  };

  const deleteImage = i => {
    const hasMorePhotos = values.photos.length > 1;
    const isMain = i.imageKey === values.mainPhoto.imageKey;
    const newPhotos = values.photos.filter(p => p.imageKey !== i.imageKey);
    handleChange({
      target: {
        name: 'photos',
        value: newPhotos,
      },
    });
    if (isMain)
      handleChange({
        target: { name: 'mainPhoto', value: hasMorePhotos ? newPhotos[0] : {} },
      });
  };

  const postImagesAtlas = size => post('images/atlas', { size }).then(handleResponse);

  const setPhotosError = message =>
    setErrors(prevState => ({
      ...prevState,
      photos: message,
    }));

  const handleUploadImage = async e => {
    setPhotosError();
    const file = e.target.files[0];
    if (file.type.includes('image/')) {
      setUploadingImage(true);
      try {
        const imageBlob = URL.createObjectURL(file);
        const imageRes = await postImagesAtlas(file.size);
        const { imageKey, url } = imageRes.message;
        const putS3Res = await putS3(url, file, file.type);
        if (putS3Res.status === 200) {
          handleChange({
            target: {
              name: 'photos',
              value: [
                ...values.photos,
                {
                  url: imageBlob,
                  imageKey,
                },
              ],
            },
          });
          if (!values.mainPhoto.imageKey)
            handleChange({
              target: {
                name: 'mainPhoto',
                value: {
                  url: imageBlob,
                  imageKey,
                },
              },
            });
        } else setPhotosError('Hubo un error subiendo la imagen. Por favor intente más tarde.');
        setUploadingImage(false);
      } catch (e) {
        setPhotosError(
          'Hubo un error subiendo la imagen. Por favor compruebe su conexión a internet e intente más tarde.'
        );
        setUploadingImage(false);
      }
    } else setPhotosError('El archivo debe ser de tipo imagen');
  };

  const handleSelectedValue = useCallback(
    value => handleChange({ target: { name: 'locale', value } }),
    [handleChange]
  );

  const renderMoreActions = () =>
    !smallScreen && (
      <LocaleSelector
        setSelectedValue={handleSelectedValue}
        selectedValue={values.locale}
        handleCancel={handleCancel}
        hasChanges={hasChanges()}
      />
    );

  return (
    <FormLayout
      handleClose={handleClose}
      renderMoreActions={renderMoreActions}
      loading={loading}
      editMode={editMode}
      submitting={submitting}
      handleSubmit={handleResult}
      isNew={isNew}
      entityName={entityName}
      handleCancel={handleCancel}
      hasChanges={hasChanges}
      handleEdit={() => setEditMode(true)}
      handleDelete={handleDelete}
    >
      <DiseasesFormComponent
        editMode={editMode}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        handleUploadImage={handleUploadImage}
        hiddenFileInput={hiddenFileInput}
        smallScreen={smallScreen}
        uploadingImage={uploadingImage}
        handleCancel={handleCancel}
        hasChanges={hasChanges}
        deleteImage={deleteImage}
        handleSelectedValue={handleSelectedValue}
      />
    </FormLayout>
  );
};

export default DiseasesFormContainer;
