export default values => {
  let errors = {};

  if (!values.name) {
    errors.name = 'Complete el nombre de la patología';
  }

  if (!values.briefDescription) {
    errors.briefDescription = 'Complete la descripción corta';
  }

  if (!values.description?.getEditorState().getCurrentContent().hasText()) {
    errors.description = 'Complete la descripción';
  }

  if (
    !values.mainPhoto.imageKey ||
    !values.photos.some(p => p.imageKey === values.mainPhoto.imageKey)
  ) {
    errors.photos = 'Debe seleccionar una imagen principal';
  }

  if (values.isStage && !values.father._id) {
    errors.father = 'Debe seleccionar la patología base';
  }
  return errors;
};
