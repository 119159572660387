import React, { useCallback, useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import isEqualWith from 'lodash/isEqualWith';
import {
  get,
  handleResponse,
  patch,
  post,
  swal500,
  handle400,
  putS3,
} from '../../../utils/network';
import validate from './validator';
import ProductsFormComponent from './ProductsFormComponent';
import { FormLayout, LocaleSelector, useFormContainer, useFormValidation } from '../../common';
import { DEFAULT_LOCALE, getCountryName } from '../../../utils/utils';
import { ENTITY_PROPS, MESSAGES } from '../ProductsTable';

export const FIELDS = {
  name: 'Nombre comercial',
  shortDescription: 'Descripción corta',
  longDescription: 'Descripción larga',
  mainPhoto: 'Imagen principal',
  photos: 'Imagenes',
  web: 'Página web',
};

const INITIAL_STATE = {
  name: '',
  shortDescription: '',
  longDescription: null,
  laboratory: {},
  drugs: [],
  categories: [],
  functions: [],
  treatments: [],
  countryCodes: [],
  photos: [],
  mainPhoto: {},
  web: '',
  isGenericProduct: false,
  ranking: [],
  locale: DEFAULT_LOCALE,
};

const ProductsFormContainer = ({ history, match }) => {
  const { id } = match.params;
  const { entityName, endpoint, path } = ENTITY_PROPS;

  const [uploadingImage, setUploadingImage] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const { handleSubmit, handleChange, handleBlur, values, errors, setErrors, resetValues } =
    useFormValidation(INITIAL_STATE, values => validate(values));

  const {
    data,
    setData,
    editMode,
    setEditMode,
    loading,
    setLoading,
    submitting,
    setSubmitting,
    isNew,
    handleClose,
    handleCancel,
    handleDelete,
    smallScreen,
  } = useFormContainer(ENTITY_PROPS, resetValues, MESSAGES.deleteConfirmation(values));

  const customizer = (prevValue, currentValue) => {
    if (prevValue?._editorState && currentValue?._editorState)
      return prevValue.toString('markdown') === currentValue.toString('markdown');
  };

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(data).forEach(key => {
      if (!isEqualWith(data[key], values[key], customizer)) changedValues.push(key);
    });
    return changedValues;
  };

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      get(`${endpoint}/${id}?locale=${values.locale.locale}&backoffice=true`)
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          const initialData = { ...parsed.message.data };
          const newData = {
            ...INITIAL_STATE,
            ...initialData,
            locale: values.locale,
            countryCodes: initialData.countryCodes
              ? initialData.countryCodes.map(c => ({
                  code: c,
                  name: getCountryName(c),
                }))
              : [],
            longDescription: initialData.longDescription
              ? RichTextEditor.createValueFromString(initialData.longDescription, 'markdown')
              : RichTextEditor.createEmptyValue(),
          };
          setData(newData);
          resetValues(newData);
          setLoading(false);
        })
        .catch(err => {
          if (err !== 403) {
            swal500(err);
          }
          history.push(path);
        });
    };

    if (id === 'new') {
      setData(INITIAL_STATE);
      setEditMode(true);
      setLoading(false);
    } else {
      getData();
    }
  }, [id, resetValues, setData, setEditMode, setLoading, history, endpoint, path, values.locale]);

  const hasChanges = () => editMode && getChangedValues(values).length > 0;

  const handleResult = e => {
    const formValid = handleSubmit(e);
    if (formValid) {
      setSubmitting(true);
      const {
        name,
        longDescription,
        shortDescription,
        photos,
        mainPhoto,
        drugs,
        categories,
        functions,
        treatments,
        countryCodes,
        laboratory,
        isGenericProduct,
        web,
        locale,
        ranking,
      } = values;
      if (isNew) {
        const body = {
          name,
          longDescription: longDescription.toString('markdown'),
          shortDescription,
          photos: photos.map(p => p.imageKey),
          mainPhoto: mainPhoto.imageKey,
          drugs: drugs.map(i => i._id),
          categories: categories.map(i => i._id),
          functions: functions.map(i => i._id),
          treatments: treatments.map(i => i._id),
          countryCodes: countryCodes.map(c => c.code.split('-')[0]),
          laboratory: laboratory._id,
          isGenericProduct,
          web: web || undefined,
          addRankingRows: ranking.map(r => ({ ...r, key: r.key._id })),
          locale: locale.locale,
        };
        post(endpoint, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(res => {
            setSubmitting(false);
            history.push({
              pathname: path,
              state: { msg: 'created' },
            });
          })
          .catch(err => {
            setSubmitting(false);
            if (err !== 400) {
              swal500(err);
            }
          });
      } else {
        let body = { locale: locale.locale };
        const changedValues = getChangedValues(values);
        if (changedValues.length === 0) {
          history.push(path);
          return;
        }
        changedValues.forEach(value => {
          if (value === 'mainPhoto') body = { ...body, mainPhoto: mainPhoto.imageKey };
          else if (value === 'photos') body = { ...body, photos: photos.map(p => p.imageKey) };
          else if (value === 'laboratory') body = { ...body, laboratory: laboratory._id };
          else if (value === 'longDescription')
            body = { ...body, longDescription: longDescription.toString('markdown') };
          else if (value === 'countryCodes')
            body = { ...body, countryCodes: countryCodes.map(c => c.code.split('-')[0]) };
          else if (value === 'treatments')
            body = { ...body, treatments: treatments.map(i => i._id) };
          else if (value === 'drugs') body = { ...body, drugs: drugs.map(i => i._id) };
          else if (value === 'categories')
            body = { ...body, categories: categories.map(i => i._id) };
          else if (value === 'functions') body = { ...body, functions: functions.map(i => i._id) };
          else if (value === 'web') body = { ...body, web: web || null };
          else if (value === 'ranking') {
            const finalRankings = values.ranking.map(p => p._id);
            const initialRankings = data.ranking.map(p => p._id);
            const addRankingRows = values.ranking
              .map(r => ({ ...r, key: r.key._id }))
              .filter(p => !p._id);
            const removeRankingRows = initialRankings.filter(p => p && !finalRankings.includes(p));
            body = { ...body, addRankingRows, removeRankingRows };
          } else body = { ...body, [value]: values[value] };
        });
        patch(`${endpoint}/${id}`, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(res => {
            setSubmitting(false);
            history.push({
              pathname: path,
              state: { msg: 'edited' },
            });
          })
          .catch(err => {
            setSubmitting(false);
            if (err !== 400) {
              swal500(err);
            }
          });
      }
    }
  };

  const deleteImage = i => {
    const hasMorePhotos = values.photos.length > 1;
    const isMain = i.imageKey === values.mainPhoto.imageKey;
    const newPhotos = values.photos.filter(p => p.imageKey !== i.imageKey);
    handleChange({
      target: {
        name: 'photos',
        value: newPhotos,
      },
    });
    if (isMain)
      handleChange({
        target: { name: 'mainPhoto', value: hasMorePhotos ? newPhotos[0] : {} },
      });
  };

  const deleteRanking = i => {
    const newRankings = values.ranking.filter((r, index) => i !== index);
    handleChange({
      target: {
        name: 'ranking',
        value: newRankings,
      },
    });
  };

  const postImagesAtlas = size => post('images/atlas', { size }).then(handleResponse);

  const setPhotosError = message =>
    setErrors(prevState => ({
      ...prevState,
      photos: message,
    }));

  const handleUploadImage = async e => {
    setPhotosError();
    const file = e.target.files[0];
    if (file.type.includes('image/')) {
      setUploadingImage(true);
      try {
        const imageBlob = URL.createObjectURL(file);
        const imageRes = await postImagesAtlas(file.size);
        const { imageKey, url } = imageRes.message;
        const putS3Res = await putS3(url, file, file.type);
        if (putS3Res.status === 200) {
          handleChange({
            target: {
              name: 'photos',
              value: [
                ...values.photos,
                {
                  url: imageBlob,
                  imageKey,
                },
              ],
            },
          });
          if (!values.mainPhoto.imageKey)
            handleChange({
              target: {
                name: 'mainPhoto',
                value: {
                  url: imageBlob,
                  imageKey,
                },
              },
            });
        } else setPhotosError('Hubo un error subiendo la imagen. Por favor intente más tarde.');
        setUploadingImage(false);
      } catch (e) {
        setPhotosError(
          'Hubo un error subiendo la imagen. Por favor compruebe su conexión a internet e intente más tarde.'
        );
        setUploadingImage(false);
      }
    } else setPhotosError('El archivo debe ser de tipo imagen');
  };

  const handleSelectedValue = useCallback(
    value => handleChange({ target: { name: 'locale', value } }),
    [handleChange]
  );

  const renderMoreActions = () =>
    !smallScreen && (
      <LocaleSelector
        setSelectedValue={handleSelectedValue}
        selectedValue={values.locale}
        handleCancel={handleCancel}
        hasChanges={hasChanges()}
      />
    );

  return (
    <FormLayout
      handleClose={handleClose}
      renderMoreActions={renderMoreActions}
      loading={loading}
      editMode={editMode}
      submitting={submitting}
      handleSubmit={handleResult}
      isNew={isNew}
      entityName={entityName}
      handleCancel={handleCancel}
      hasChanges={hasChanges}
      handleEdit={() => setEditMode(true)}
      handleDelete={handleDelete}
    >
      <ProductsFormComponent
        editMode={editMode}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        history={history}
        handleUploadImage={handleUploadImage}
        hiddenFileInput={hiddenFileInput}
        smallScreen={smallScreen}
        uploadingImage={uploadingImage}
        handleCancel={handleCancel}
        hasChanges={hasChanges}
        deleteImage={deleteImage}
        handleSelectedValue={handleSelectedValue}
        deleteRanking={deleteRanking}
      />
    </FormLayout>
  );
};

export default ProductsFormContainer;
