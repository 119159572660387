import React from 'react';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/es';
import styles from './styles';
import { SecondaryButton, Surface } from '../common';

const SessionItem = ({ session, last, classes, handleDelete, deleting }) => {
  moment.locale('es');
  return (
    <Surface className={classes.itemContainer}>
      <div style={{ color: 'grey' }}>
        <p>
          <b>Creada:</b> {moment(session.created).fromNow()}
        </p>
        <p>
          <b>Navegador:</b> {session.userAgent}
        </p>
      </div>
      {!session.invalid && (
        <div className={classes.buttonContainer}>
          <SecondaryButton onClick={() => handleDelete(session._id)} disabled={deleting}>
            Cerrar sesión
          </SecondaryButton>
        </div>
      )}
    </Surface>
  );
};

export default withStyles(styles)(SessionItem);
