export const MONGOID_REGEX = /^[a-f\d]{24}$/i;

export default values => {
  let errors = {};

  if (!values.country) {
    errors.country = 'Complete el país';
  }

  if (!values.searchType) {
    errors.searchType = 'Complete el tipo de búsqueda';
  }

  if (!values.key) {
    errors.key = 'Complete el valor de búsqueda';
  }

  if (!values.position) {
    errors.position = 'Complete la posición';
  } else if (values.position < 1) {
    errors.position = 'La posición debe ser un número mayor a 0';
  }

  return errors;
};
