import moment from 'moment';

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
export const NAME_REGEX = /^([a-zA-ZÀ-úÜü]+\s?)+$/;

export default (values, isNewUser) => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = 'Complete el nombre';
  } else if (!NAME_REGEX.test(values.firstName)) {
    errors.firstName = 'El nombre debe ser un nombre válido';
  }

  if (!values.lastName) {
    errors.lastName = 'Complete el apellido';
  } else if (!NAME_REGEX.test(values.lastName)) {
    errors.lastName = 'El apellido debe ser un apellido válido';
  }

  if (isNewUser) {
    if (!values.password) {
      errors.password = 'Complete la contraseña';
    } else if (values.password.length < 8 || values.password.length > 20) {
      errors.password = 'La contraseña debe tener entre 8 y 20 caracteres';
    } else if (!PASSWORD_REGEX.test(values.password)) {
      errors.password =
        'La contraseña debe tener al menos un caracter en minúscula, uno en mayúscula y un numéro';
    }
  }

  if (!values.email) {
    errors.email = 'Complete el email';
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = 'El mail debe ser un mail válido';
  }

  if (!values.birthDate) errors.birthDate = 'Complete la fecha de nacimiento';

  if (!values.phoneNumber) errors.phoneNumber = 'Complete el número de teléfono';

  if (!values.birthCountry) errors.birthCountry = 'Complete el país de nacimiento';

  if (!values.region) {
    errors.region = 'Complete la región de residencia';
    if (!values.currentCountry) errors.currentCountry = 'Complete el país de residencia';
  }

  if (!values.universityYear) errors.universityYear = 'Complete el año de graduación';
  else if (values.universityYear < 1900 || values.universityYear > moment().year())
    errors.universityYear = 'El año de graduación es invalido';

  if (!values.university) errors.university = 'Complete la universidad';

  if (!values.institution) errors.institution = 'Complete la institución';

  if (!values.universityCountry) errors.universityCountry = 'Complete el país de la universidad';

  if (!values.professionCategory) errors.professionCategory = 'Compelete la profesión';

  if (!values.professionType)
    errors.professionType = 'Complete el tipo/especialidad de su profesión';

  if (!values.license) errors.license = 'Complete el número de matrícula';

  return errors;
};
