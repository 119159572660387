import React, { useEffect, useCallback } from 'react';
import { handleResponse, post, patch, swal500, get, handle400 } from '../../../utils/network';
import { getCountryName, getRegionName } from '../../../utils/utils';
import {
  useFormValidation,
  useGetPermissions,
  usePermissionSelector,
  useFormContainer,
  FormLayout,
} from '../../common';
import validateUser from './validator';
import UserFormComponent from './UserFormComponent';
import { MESSAGES, ENTITY_PROPS } from '../UserTable';
import { isEqual } from 'lodash';
import moment from 'moment';

export const FIELDS = {
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'Email',
  password: 'Contraseña',
  role: 'Rol',
  university: 'Universidad',
  universityYear: 'Año de graduación',
  universityCountry: 'País de la universidad',
  license: 'Número de matrícula',
  phoneNumber: 'Número de celular',
  birthDate: 'Fecha de nacimiento',
  birthCountry: 'País de nacimiento',
  currentCountry: 'País de residencia',
  region: 'Provincia/Estado de residencia',
  institution: 'Institución',
  professionCategory: 'Profesión',
  professionType: 'Tipo/Especialidad de profesión',
};

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phoneNumber: '',
  institution: '',
  professionCategory: '',
  professionType: '',
  university: '',
  universityCountry: '',
  universityYear: '',
  license: '',
  birthDate: null,
  currentCountry: '',
  region: '',
  birthCountry: '',
  role: '',
  permissions: [],
};

const UserFormContainer = ({ history, match }) => {
  const { id } = match.params;
  const { entityName, endpoint, path } = ENTITY_PROPS;

  const { handleSubmit, handleChange, handleBlur, values, errors, setErrors, resetValues } =
    useFormValidation(INITIAL_STATE, values => validateUser(values, isNew));

  const {
    data,
    setData,
    editMode,
    setEditMode,
    loading,
    setLoading,
    submitting,
    setSubmitting,
    isNew,
    handleClose,
    handleCancel,
    handleDelete,
  } = useFormContainer(ENTITY_PROPS, resetValues, MESSAGES.deleteConfirmation(values));

  const handleChangeAutocomplete = useCallback(
    (name, value) => {
      handleChange({
        target: {
          name: name,
          value: value,
        },
      });
    },
    [handleChange]
  );

  const {
    handleChangePermission,
    handleChangeCategory,
    permissions,
    setPermissions,
    getPermissionsList,
    permissionsDidChange,
  } = usePermissionSelector();

  const permissionMapper = useCallback(
    permission => ({
      ...permission,
      checked:
        values.role?.permissions?.includes(permission._id) ||
        data.permissions?.map(p => p._id).includes(permission._id),
      disabled: values.role?.permissions?.includes(permission._id),
    }),
    [data.permissions, values.role]
  );

  const { loadingPermissions, getPermissions } = useGetPermissions(
    permissionMapper,
    setPermissions
  );

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(data).forEach(key => {
      if (!isEqual(data[key], values[key])) changedValues.push(key);
    });
    if (data.role?._id !== values.role?._id) delete changedValues.role;
    if (
      permissionsDidChange(data.permissions.map(p => p._id)) ||
      changedValues.some(v => v === 'role')
    ) {
      changedValues.push('permissions');
    }
    return changedValues.filter(v => v !== 'created' && v !== 'rolePermissions' && v !== 'region');
  };

  useEffect(() => {
    const getUser = () => {
      setLoading(true);
      get(`${endpoint}/${id}`)
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          if (parsed.error) history.push(path);
          else {
            const { degree, birthCountry, currentCountry, profession } = parsed.message;
            const user = {
              ...INITIAL_STATE,
              ...parsed.message,
              license: degree?.license,
              university: degree?.university,
              universityYear: degree?.year,
              birthCountry: { code: birthCountry, name: getCountryName(birthCountry) },
              universityCountry: { code: degree?.country, name: getCountryName(degree?.country) },
              currentCountry: { code: currentCountry, name: getCountryName(currentCountry) },
              region: { code: currentCountry, name: getRegionName(currentCountry) },
              professionCategory: profession?.root,
              professionType: profession?.child,
            };
            setData(user);
            resetValues(user);
            setLoading(false);
          }
        });
    };

    getPermissions();

    if (id === 'new') {
      setData(INITIAL_STATE);
      setEditMode(true);
      setLoading(false);
    } else {
      getUser();
    }
  }, [endpoint, getPermissions, history, id, path, resetValues, setData, setEditMode, setLoading]);

  const hasChanges = () => editMode && getChangedValues(values).length > 0;

  const handleResult = e => {
    const formValid = handleSubmit(e);
    if (formValid) {
      setSubmitting(true);
      const {
        password,
        email,
        firstName,
        lastName,
        birthDate,
        phoneNumber,
        birthCountry,
        region,
        university,
        universityYear,
        universityCountry,
        license,
        institution,
        professionType,
        professionCategory,
        role,
      } = values;
      let body = {};
      if (isNew) {
        body = {
          ...body,
          password,
          email,
          firstName,
          lastName,
          phoneNumber, //TODO: PhoneSelector
          birthCountry: birthCountry.code,
          birthDate: moment.utc(birthDate).startOf('D'),
          currentCountry: region.code,
          degree: {
            university,
            country: universityCountry.code,
            year: universityYear,
            license,
          },
          institution: institution._id,
          profession: {
            root: { id: professionCategory._id },
            child: {
              [professionType._id ? 'id' : 'name']: professionType._id || professionType.name,
            },
          },
          role: role._id,
          permissions: getPermissionsList,
          locale: 'es',
        };
        post(endpoint, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'created' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      } else {
        const changedValues = getChangedValues(values);
        if (changedValues.length === 0) {
          history.push(path);
          return;
        }
        changedValues.forEach(value => {
          let changes;
          if (value === 'role') {
            changes = role._id ? { role: role._id } : { deleteRole: true };
          } else if (value === 'permissions') {
            changes = { permissions: getPermissionsList };
          } else if (value === 'birthDate') {
            changes = { birthDate: moment.utc(birthDate).startOf('D') };
          } else if (value === 'institution') {
            changes = { institution: institution._id };
          } else if (value === 'birthCountry') {
            changes = { birthCountry: birthCountry.code };
          } else if (value === 'currentCountry' || value === 'region') {
            changes = { currentCountry: region.code };
          } else if (
            value === 'university' ||
            value === 'universityCountry' ||
            value === 'universityYear' ||
            value === 'license'
          ) {
            changes = {
              degree: {
                university,
                country: universityCountry.code,
                year: universityYear,
                license,
              },
            };
          } else if (value === 'professionCategory' || value === 'professionType') {
            changes = {
              profession: {
                root: { id: professionCategory._id },
                child: {
                  [professionType._id ? 'id' : 'name']: professionType._id || professionType.name,
                },
              },
            };
          } else {
            changes = { [value]: values[value] };
          }
          body = { ...body, ...changes };
        });

        patch(`${endpoint}/${data._id}`, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'edited' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      }
    }
  };

  return (
    <FormLayout
      handleClose={handleClose}
      loading={loading}
      editMode={editMode}
      submitting={submitting}
      handleSubmit={handleResult}
      handleCancel={handleCancel}
      isNew={isNew}
      entityName={entityName}
      hasChanges={hasChanges}
      handleEdit={() => setEditMode(true)}
      handleDelete={handleDelete}
    >
      <UserFormComponent
        submitting={submitting}
        editMode={editMode}
        isNewUser={isNew}
        values={values}
        errors={errors}
        handleChange={handleChange}
        handleChangeAutocomplete={handleChangeAutocomplete}
        handleBlur={handleBlur}
        loadingPermissions={loadingPermissions}
        permissions={permissions}
        handleChangePermission={handleChangePermission}
        handleChangeCategory={handleChangeCategory}
      />
    </FormLayout>
  );
};

export default UserFormContainer;
