import React from 'react';
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { AddPhotoAlternate as AddPhotoIcon } from '@material-ui/icons';
import { DiseaseImage, Title } from './';

const useStyles = makeStyles(theme => ({
  imageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
    marginBottom: 8,
  },
  newImageContainer: {
    borderRadius: 0,
    height: 150,
    width: 150,
    display: 'flex',
    backgroundColor: '#F3F3F3',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ImageSelector = ({
  hiddenFileInput,
  deleteImage,
  handleUploadImage,
  uploadingImage,
  onFav,
  photos,
  mainPhoto,
  error,
  editMode,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div>
      <Title text="Imágenes" />
      <div className={classes.imageContainer}>
        {photos.map((i, index) => (
          <DiseaseImage
            key={index}
            isMain={i.imageKey === mainPhoto.imageKey}
            onFav={() => onFav(i)}
            onDelete={() => deleteImage(i)}
            image={i.url}
            editMode={editMode}
          />
        ))}
        <input
          accept="image/*"
          type="file"
          ref={hiddenFileInput}
          onChange={handleUploadImage}
          style={{ display: 'none' }}
        />
        <div style={{ border: error && '1px solid ' + theme.palette.error.main }}>
          {editMode &&
            (uploadingImage ? (
              <div className={classes.newImageContainer}>
                <CircularProgress />
              </div>
            ) : (
              <Tooltip placement="right-end" title="Agregar imagen">
                <IconButton
                  className={classes.newImageContainer}
                  onClick={() => hiddenFileInput.current.click()}
                >
                  <AddPhotoIcon style={{ fontSize: 80 }} />
                </IconButton>
              </Tooltip>
            ))}
        </div>
      </div>
      {editMode && error && (
        <Typography style={{ color: theme.palette.error.main, marginTop: 10, fontSize: 12 }}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default ImageSelector;
