export default theme => ({
  root: {
    width: '100%',
    marginBottom: 20,
    margin: '0 auto',
    padding: '0 20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      width: '100%',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    color: 'grey',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0',
    padding: '5px 15px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  itemDivider: {
    height: 1,
    backgroundColor: '#DDDDDD',
    margin: '5px 0',
  },
  buttonContainer: {
    minWidth: 145,
    display: 'flex',
    justifyContent: 'center',
  },
  deleteAll: {
    color: '#8e4b33',
    cursor: 'pointer',
    fontWeight: 600,
  },
  noContent: {
    color: '#a5a5a5',
    margin: 0,
  },
  divider: {
    height: 20,
  },
});
