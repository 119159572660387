import React from 'react';
import { AutocompleteChipSection, LocaleSelector, TextInput } from '../../common';
import { FIELDS } from './GenericsFormContainer';

const localeSelectorStyles = { margin: '15px 0px 0px' };

const TreatmentsFormComponent = ({
  editMode,
  errors,
  handleChange,
  handleBlur,
  values,
  smallScreen,
  hasChanges,
  handleCancel,
  handleSelectedValue,
  history,
}) => {
  return (
    <>
      {smallScreen && (
        <LocaleSelector
          fullWidth
          style={localeSelectorStyles}
          setSelectedValue={handleSelectedValue}
          selectedValue={values.locale}
          hasChanges={hasChanges()}
          handleCancel={handleCancel}
        />
      )}
      <TextInput
        name="name"
        value={values.name}
        error={errors.name}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['name']}
        disabled={!editMode}
      />
      <AutocompleteChipSection
        editMode={editMode}
        title="Categorías"
        field="categories"
        history={history}
        value={values.categories}
        handleChange={handleChange}
        likeLocale={true}
        noItemsText="No posee categorías"
        selectItemText="Seleccione una categoría"
        helpText="Si la categoría deseada todavía no existe, deberá crearla y luego agregarla"
        smallScreen={smallScreen}
      />
      <AutocompleteChipSection
        editMode={editMode}
        title="Funciones"
        field="functions"
        apiField="drug-functions"
        history={history}
        value={values.functions}
        handleChange={handleChange}
        likeLocale={true}
        noItemsText="No posee funciones"
        selectItemText="Seleccione una función"
        helpText="Si la función deseada todavía no existe, deberá crearla y luego agregarla"
        smallScreen={smallScreen}
      />
    </>
  );
};

export default TreatmentsFormComponent;
