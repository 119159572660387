import React from 'react';
import { TableContainer } from '../common';

export const MESSAGES = {
  title: 'Listado de roles',
  created: 'El Rol ha sido creado correctamente',
  edited: 'El Rol ha sido editado correctamente',
  deleted: 'El Rol ha sido eliminado correctamente',
  deleteConfirmation: role => `¿Estas seguro/a de que queres eliminar el rol ${role.role}?`,
};

const COLUMNS = [
  {
    id: 'role',
    label: 'Rol',
    minWidth: 100,
  },
  {
    id: 'usersCount',
    label: 'Cantidad de usuarios',
    minWidth: 100,
    hidden: {
      xsDown: true,
    },
  },
];

export const ENTITY_PROPS = {
  entityName: 'rol',
  endpoint: 'roles',
  path: '/home/roles',
};

const RolesTable = () => {
  return <TableContainer entityProps={ENTITY_PROPS} messages={MESSAGES} columns={COLUMNS} />;
};

export default RolesTable;
