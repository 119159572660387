import React from 'react';
import { Layout } from '../components/common';
import { Route, Redirect, Switch } from 'react-router-dom';
import UserTable from '../components/Users/UserTable';
import UserFormContainer from '../components/Users/UsersForm/UserFormContainer';
import RolesTableContainer from '../components/Roles/RolesTable';
import RolesFormContainer from '../components/Roles/RolesForm/RolesForm';
import TreatmentsTableContainer from '../components/Treatments/TreatmentsTable';
import TreatmentsFormContainer from '../components/Treatments/TreatmentsForm/TreatmentsFormContainer';
import DiseasesTableContainer from '../components/Diseases/DiseasesTable';
import DiseasesFormContainer from '../components/Diseases/DiseasesForm/DiseasesFormContainer';
import GenericsTableContainer from '../components/Generics/GenericsTable';
import GenericsFormContainer from '../components/Generics/GenericsForm/GenericsFormContainer';
import ProductsTableContainer from '../components/Products/ProductsTable';
import ProductsFormContainer from '../components/Products/ProductsForm/ProductsFormContainer';
import SessionsList from '../components/Sessions/SessionsList';
import ProfileFormContainer from '../components/Profile/ProfileFormContainer';
import FunctionsFormContainer from '../components/Functions/FunctionsForm/FunctionsFormContainer';
import FunctionsTable from '../components/Functions/FunctionsTable';
import CategoriesFormContainer from '../components/Categories/CategoriesForm/CategoriesFormContainer';
import CategoriesTable from '../components/Categories/CategoriesTable';
import { getCookie } from '../utils/utils';

export default function Home(props) {
  const isLogged = getCookie('isLogged');
  return !isLogged ? (
    <Redirect from="/home/*" to="/login" />
  ) : (
    <Layout history={props.history}>
      <Switch>
        <Route exact path="/home/users" component={UserTable} />
        <Route exact path="/home/users/:id" component={UserFormContainer} />
        <Route exact path="/home/roles" component={RolesTableContainer} />
        <Route exact path="/home/roles/:id" component={RolesFormContainer} />
        <Route exact path="/home/treatments" component={TreatmentsTableContainer} />
        <Route exact path="/home/treatments/:id" component={TreatmentsFormContainer} />
        <Route exact path="/home/diseases" component={DiseasesTableContainer} />
        <Route exact path="/home/diseases/:id" component={DiseasesFormContainer} />
        <Route exact path="/home/generics" component={GenericsTableContainer} />
        <Route exact path="/home/generics/:id" component={GenericsFormContainer} />
        <Route exact path="/home/categories" component={CategoriesTable} />
        <Route exact path="/home/categories/:id" component={CategoriesFormContainer} />
        <Route exact path="/home/products" component={ProductsTableContainer} />
        <Route exact path="/home/products/:id" component={ProductsFormContainer} />
        <Route exact path="/home/drug-functions" component={FunctionsTable} />
        <Route exact path="/home/drug-functions/:id" component={FunctionsFormContainer} />
        <Route exact path="/home/sessions" component={SessionsList} />
        <Route exact path="/home/profile" component={ProfileFormContainer} />
        <Redirect from="/home" to={JSON.parse(getCookie('routeOptions'))[0].path} exact />
        <Redirect from="/home/*" to="/404" />
      </Switch>
    </Layout>
  );
}
