import React, { Component } from 'react';
import DrawerLeft from './DrawerLeft/DrawerLeft';
import { handleResponse, post } from '../../utils/network';
import withStyles from '@material-ui/core/styles/withStyles';
import { deleteCookie } from '../../utils/utils';
import { Header } from '.';

const styles = theme => ({
  childrenContainer: {
    margin: '20px 40px',
    [theme.breakpoints.down('md')]: {
      margin: 20,
    },
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0',
    },
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
  },
});

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
  }

  handleDrawerToggle = value => {
    if (value !== undefined) {
      this.setState({ mobileOpen: value });
    } else {
      this.setState(prevState => ({ mobileOpen: !prevState.mobileOpen }));
    }
  };

  logout = () => {
    deleteCookie('isLogged');
    deleteCookie('routeOptions');
    deleteCookie('userId');
    deleteCookie('name');
    deleteCookie('token');
    post('auth/logout', '')
      .then(res => handleResponse(res, this.props))
      .then(() => {
        deleteCookie('token');
        this.props.history.push('/login');
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <DrawerLeft
          history={this.props.history}
          mobileOpen={this.state.mobileOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          logout={this.logout}
        />
        <div className={classes.layout}>
          <Header
            title="Curapp"
            handleDrawerToggle={this.handleDrawerToggle}
            logout={this.logout}
            handleProfileClick={this.handleProfileClick}
          />
          <div className={classes.childrenContainer}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Layout);
