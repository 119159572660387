import React, { useCallback, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import FunctionsFormComponent from './FunctionsFormComponent';
import { get, handleResponse, patch, post, swal500, handle400 } from '../../../utils/network';
import { FormLayout, LocaleSelector, useFormContainer, useFormValidation } from '../../common';
import validate from './validator';
import { DEFAULT_LOCALE } from '../../../utils/utils';
import { ENTITY_PROPS, MESSAGES } from '../FunctionsTable';

export const FIELDS = {
  name: 'Nombre de la la función',
};

const INITIAL_STATE = {
  name: '',
  locale: DEFAULT_LOCALE,
};

const FunctionsFormContainer = ({ history, match }) => {
  const { id } = match.params;
  const { entityName, endpoint, path } = ENTITY_PROPS;

  const { handleSubmit, handleChange, handleBlur, values, errors, setErrors, resetValues } =
    useFormValidation(INITIAL_STATE, values => validate(values));

  const {
    data,
    setData,
    editMode,
    setEditMode,
    loading,
    setLoading,
    submitting,
    setSubmitting,
    isNew,
    handleClose,
    handleCancel,
    handleDelete,
    smallScreen,
  } = useFormContainer(ENTITY_PROPS, resetValues, MESSAGES.deleteConfirmation(values));

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(data).forEach(key => {
      if (!isEqual(data[key], values[key])) changedValues.push(key);
    });
    return changedValues;
  };

  useEffect(() => {
    const getItems = () => {
      setLoading(true);
      get(`${endpoint}/${id}?locale=${values.locale.locale}`)
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          const newData = {
            ...INITIAL_STATE,
            ...parsed.message.data,
            locale: values.locale,
          };
          setData(newData);
          resetValues(newData);
          setLoading(false);
        })
        .catch(err => {
          swal500(err);
          history.push(path);
        });
    };
    if (id === 'new') {
      setData(INITIAL_STATE);
      setEditMode(true);
      setLoading(false);
    } else {
      getItems();
    }
  }, [
    id,
    resetValues,
    setData,
    setEditMode,
    setLoading,
    history,
    endpoint,
    path,
    values.locale,
    handleChange,
  ]);

  const hasChanges = () => {
    const changedValues = getChangedValues(values);
    return (
      editMode &&
      (changedValues.length > 1 || (changedValues.length === 1 && changedValues[0] !== 'locale'))
    );
  };

  const handleResult = e => {
    const formValid = handleSubmit(e);
    if (formValid) {
      setSubmitting(true);
      const { name, locale } = values;
      if (isNew) {
        const body = { name, locale: locale.locale };
        post(endpoint, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'created' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      } else {
        let body = { locale: locale.locale };
        const changedValues = getChangedValues(values);
        if (changedValues.length === 0) {
          history.push(path);
          return;
        }
        changedValues.forEach(value => {
          body = { ...body, [value]: values[value] };
        });
        patch(`${endpoint}/${id}`, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'edited' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      }
    }
  };

  const handleSelectedValue = useCallback(
    value => handleChange({ target: { name: 'locale', value } }),
    [handleChange]
  );

  const renderMoreActions = () =>
    !smallScreen && (
      <LocaleSelector
        setSelectedValue={handleSelectedValue}
        selectedValue={values.locale}
        hasChanges={hasChanges()}
        handleCancel={handleCancel}
      />
    );

  return (
    <FormLayout
      handleClose={handleClose}
      renderMoreActions={renderMoreActions}
      loading={loading}
      editMode={editMode}
      submitting={submitting}
      handleSubmit={handleResult}
      isNew={isNew}
      entityName={entityName}
      handleCancel={handleCancel}
      hasChanges={hasChanges}
      handleDelete={handleDelete}
      handleEdit={() => setEditMode(true)}
    >
      <FunctionsFormComponent
        editMode={editMode}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        smallScreen={smallScreen}
        handleCancel={handleCancel}
        hasChanges={hasChanges}
        isNew={id === 'new'}
        handleSelectedValue={handleSelectedValue}
      />
    </FormLayout>
  );
};

export default FunctionsFormContainer;
