import React, { useCallback, useState, memo } from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { AddCircle, Help as HelpIcon } from '@material-ui/icons';
import ApiAutocomplete from './ApiAutocomplete';

function ApiAutocompleteAdd({
  path,
  field,
  title,
  error,
  smallScreen,
  helpText,
  onAdd,
  likeLocale = true,
  filterIds,
  idField,
}) {
  const [value, setValue] = useState({});
  const [resetValue, setResetValue] = useState(false);
  const handleAdd = () => {
    if (value._id) {
      onAdd(value);
      setResetValue(r => !r);
      setValue({});
    }
  };
  const handleChange = useCallback(value => setValue(value), [setValue]);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <ApiAutocomplete
        path={path}
        field={field}
        value={value || null}
        label={title}
        handleChange={handleChange}
        error={error}
        dependsOn={resetValue}
        likeLocale={likeLocale}
        filterIds={filterIds}
        idField={idField}
        compact
      />
      {smallScreen ? (
        <IconButton style={{ padding: 0, marginLeft: 5 }} onClick={handleAdd}>
          <AddCircle color="primary" />
        </IconButton>
      ) : (
        <Button
          onClick={handleAdd}
          color="primary"
          variant="contained"
          style={{ padding: '2px 7px', marginLeft: 10 }}
        >
          Agregar
        </Button>
      )}
      {!smallScreen && (
        <Tooltip title={helpText}>
          <HelpIcon style={{ color: '#707070', marginBottom: 5, marginLeft: 5, fontSize: 20 }} />
        </Tooltip>
      )}
    </div>
  );
}

export default memo(ApiAutocompleteAdd);
