export default theme => ({
  background: {
    backgroundColor: '#efefef',
    //backgroundColor: '#c6d6d8',
    [theme.breakpoints.down(516)]: {
      backgroundColor: 'white',
    },
  },
  gridLogin: {
    minHeight: '100vh',
  },
  title: {
    marginBottom: 15,
    color: '#353535',
    marginTop: 20,
    fontSize: 30,
  },
  formLogin: {
    marginTop: '2.5%',
    marginBottom: '2.5%',
    width: 400,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '35px 50px 35px 50px',
    borderRadius: 5,
    boxShadow: '0px 0px 12px -8px rgba(0, 0, 0, 0.75)',
    [theme.breakpoints.down(516)]: {
      minHeight: '80vh',
      maxHeight: '90vh',
      maxWidth: '80%',
      padding: 10,
      margin: 0,
      boxShadow: 'none',
      borderRadius: 0,
    },
    backgroundColor: 'white',
  },
  inputLogin: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    marginTop: 20,
    color: 'white',
  },
  goLogin: {
    border: 'none',
    color: '#562513',
    cursor: 'pointer',
    marginBottom: 0,
    marginTop: 25,
  },
  loadingContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'fixed',
    zIndex: 1,
  },
});
