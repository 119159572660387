import React from 'react';
import { TableContainer } from '../common';

export const MESSAGES = {
  newButton: 'Nueva patología',
  title: 'Listado de patologías',
  created: 'La patología ha sido creada correctamente',
  edited: 'La patología ha sido editada correctamente',
  deleted: 'La patología ha sido eliminada correctamente',
  deleteConfirmation: disease =>
    `¿Estas seguro/a de que queres eliminar la patología ${disease.name}?`,
};

const COLUMNS = [
  {
    id: 'name',
    label: 'Nombre',
    minWidth: 100,
  },
];

export const ENTITY_PROPS = {
  entityName: 'patología',
  endpoint: 'diseases',
  path: '/home/diseases',
};

const DiseaseTable = () => {
  return <TableContainer entityProps={ENTITY_PROPS} messages={MESSAGES} columns={COLUMNS} />;
};

export default DiseaseTable;
