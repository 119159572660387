import React from 'react';
import { Card, CardMedia, IconButton, makeStyles, Typography, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  productData: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: WIDTH - 80,
    height: HEIGHT,
    padding: 5,
  },
}));

const HEIGHT = 90;
const WIDTH = 220;

const ProductCard = ({ item, onDelete, onPress, editMode }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const handlePress = () => {
    if (onPress) {
      onPress();
    }
  };
  return (
    <Card
      elevation={4}
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: WIDTH,
        height: HEIGHT,
        margin: '10px 10px 10px 10px',
        borderRadius: 0,
      }}
      onClick={handlePress}
    >
      {loading && (
        <Skeleton
          animation="wave"
          width={80}
          height={HEIGHT}
          variant="rect"
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000 }}
        />
      )}
      <CardMedia
        component="img"
        image={item?.mainPhoto}
        style={{ width: 80, height: HEIGHT, backgroundColor: '#eeeeee', lineHeight: HEIGHT + 'px' }}
        alt="Imagen"
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
      />
      <div className={classes.productData}>
        <Tooltip title={item.name}>
          <Typography noWrap style={{ textAlign: 'center', fontSize: 14 }}>
            {item.name}
          </Typography>
        </Tooltip>
        {item.laboratory?.name && (
          <Tooltip title={item.laboratory.name}>
            <Typography noWrap style={{ textAlign: 'center', fontSize: 12, color: 'grey' }}>
              {item.laboratory.name}
            </Typography>
          </Tooltip>
        )}
      </div>
      {editMode && (
        <IconButton
          onClick={onDelete}
          style={{ position: 'absolute', top: 0, right: 0, padding: 2 }}
        >
          <CloseIcon style={{ fontSize: 18 }} />
        </IconButton>
      )}
    </Card>
  );
};

export default ProductCard;
