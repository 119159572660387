import React, { useCallback, useEffect } from 'react';
import isEqual from 'lodash/isEqualWith';
import CategoriesFormComponent from './CategoriesFormComponent';
import { get, handleResponse, patch, post, swal500, handle400 } from '../../../utils/network';
import { FormLayout, LocaleSelector, useFormContainer, useFormValidation } from '../../common';
import validate from './validator';
import { DEFAULT_LOCALE } from '../../../utils/utils';
import { ENTITY_PROPS, MESSAGES } from '../CategoriesTable';

export const FIELDS = {
  name: 'Nombre de la categoría',
  description: 'Descripción de la categoría',
};

const INITIAL_STATE = {
  name: '',
  description: '',
  father: '',
  locale: DEFAULT_LOCALE,
};

const CategoriesFormContainer = ({ history, match }) => {
  const { id } = match.params;
  const { entityName, endpoint, path } = ENTITY_PROPS;

  const { handleSubmit, handleChange, handleBlur, values, errors, setErrors, resetValues } =
    useFormValidation(INITIAL_STATE, values => validate(values));

  const {
    data,
    setData,
    editMode,
    setEditMode,
    loading,
    setLoading,
    submitting,
    setSubmitting,
    isNew,
    handleClose,
    handleCancel,
    handleDelete,
    smallScreen,
  } = useFormContainer(ENTITY_PROPS, resetValues, MESSAGES.deleteConfirmation(values));

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(data).forEach(key => {
      if (!isEqual(data[key], values[key])) changedValues.push(key);
    });
    return changedValues;
  };

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      get(`${endpoint}/${id}?locale=${values.locale.locale}`)
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          const newData = {
            ...INITIAL_STATE,
            ...parsed.message.data,
            locale: values.locale,
          };
          setData(newData);
          resetValues(newData);
          setLoading(false);
        })
        .catch(err => {
          swal500(err);
          history.push(path);
        });
    };

    if (id === 'new') {
      setData(INITIAL_STATE);
      setEditMode(true);
      setLoading(false);
    } else {
      getData();
    }
  }, [id, resetValues, setData, setEditMode, setLoading, history, endpoint, path, values.locale]);

  const hasChanges = () => {
    const changedValues = getChangedValues(values);
    return (
      editMode &&
      (changedValues.length > 1 || (changedValues.length === 1 && changedValues[0] !== 'locale'))
    );
  };

  const handleResult = e => {
    const formValid = handleSubmit(e);
    if (formValid) {
      setSubmitting(true);
      const { name, description, father, locale } = values;
      if (isNew) {
        const body = {
          name,
          description,
          father: father._id,
          locale: locale.locale,
        };
        post(endpoint, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'created' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      } else {
        let body = { locale: locale.locale };
        const changedValues = getChangedValues(values);
        if (changedValues.length === 0) {
          history.push(path);
          return;
        }
        changedValues.forEach(value => {
          if (value === 'father') {
            body = { ...body, father: father._id || null };
          } else body = { ...body, [value]: values[value] };
        });
        patch(`${endpoint}/${id}`, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'edited' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      }
    }
  };

  const handleSelectedValue = useCallback(
    value => handleChange({ target: { name: 'locale', value } }),
    [handleChange]
  );

  const renderMoreActions = () =>
    !smallScreen && (
      <LocaleSelector
        setSelectedValue={handleSelectedValue}
        selectedValue={values.locale}
        handleCancel={handleCancel}
        hasChanges={hasChanges()}
      />
    );

  return (
    <FormLayout
      handleClose={handleClose}
      renderMoreActions={renderMoreActions}
      loading={loading}
      editMode={editMode}
      submitting={submitting}
      handleSubmit={handleResult}
      isNew={isNew}
      entityName={entityName}
      handleCancel={handleCancel}
      hasChanges={hasChanges}
      handleEdit={() => setEditMode(true)}
      handleDelete={handleDelete}
    >
      <CategoriesFormComponent
        editMode={editMode}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        history={history}
        smallScreen={smallScreen}
        handleCancel={handleCancel}
        hasChanges={hasChanges}
        isNew={isNew}
        handleSelectedValue={handleSelectedValue}
        id={id}
      />
    </FormLayout>
  );
};

export default CategoriesFormContainer;
