export default theme => ({
  imageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
  },
  title: {
    fontSize: 16,
    marginTop: 10,
    paddingBottom: 5,
  },
});
