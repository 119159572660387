import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { FIELDS } from './UserFormContainer';
import {
  ApiAutocomplete,
  PermissionsSelector,
  PasswordInput,
  CountryAutocomplete,
  ArrayAutocomplete,
  TextInput,
  DatePicker,
} from '../../common';
import { getCookie, getCountrySubs } from '../../../utils/utils';
import styles from './styles';

const UserFormComponent = ({
  classes,
  errors,
  handleChange,
  handleChangeAutocomplete,
  handleBlur,
  loadingPermissions,
  values,
  editMode,
  isNewUser,
  permissions,
  handleChangePermission,
  handleChangeCategory,
}) => {
  const [availableRegions, setAvailableRegions] = useState(
    values.currentCountry.code ? getCountrySubs(values.currentCountry.code.split('-')[0]) : []
  );
  const isCurrentUser = getCookie('userId') === values?._id;

  useEffect(() => {
    if (values.currentCountry.code) {
      setAvailableRegions(getCountrySubs(values.currentCountry.code.split('-')[0]));
    } else {
      setAvailableRegions([]);
      handleChangeAutocomplete('region', '');
    }
  }, [handleChangeAutocomplete, values.currentCountry.code]);

  const handleChangeDate = useCallback(
    date => handleChange({ target: { name: 'birthDate', value: date } }),
    [handleChange]
  );

  const handleChangeBirthCountry = useCallback(
    value => handleChangeAutocomplete('birthCountry', value),
    [handleChangeAutocomplete]
  );

  const handleChangeCurrentCountry = useCallback(
    value => handleChangeAutocomplete('currentCountry', value),
    [handleChangeAutocomplete]
  );

  const handleChangeUniversityCountry = useCallback(
    value => handleChangeAutocomplete('universityCountry', value),
    [handleChangeAutocomplete]
  );

  const handleChangeCurrentRegion = useCallback(
    value => handleChangeAutocomplete('region', value),
    [handleChangeAutocomplete]
  );

  const handleChangeRole = useCallback(
    value => handleChangeAutocomplete('role', value),
    [handleChangeAutocomplete]
  );

  const handleChangeInstitution = useCallback(
    value => handleChangeAutocomplete('institution', value),
    [handleChangeAutocomplete]
  );

  const handleChangeProfessionCategory = useCallback(
    value => handleChangeAutocomplete('professionCategory', value),
    [handleChangeAutocomplete]
  );

  const handleChangeProfessionType = useCallback(
    value => handleChangeAutocomplete('professionType', value),
    [handleChangeAutocomplete]
  );

  return (
    <>
      <p className={classes.sectionTitle}>Información de la cuenta: </p>
      <TextInput
        name="firstName"
        value={values.firstName}
        error={errors.firstName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['firstName']}
        disabled={!editMode}
        hide={!editMode && !values.firstName}
      />
      <TextInput
        name="lastName"
        value={values.lastName}
        error={errors.lastName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['lastName']}
        disabled={!editMode}
        hide={!editMode && !values.lastName}
      />
      <TextInput
        name="email"
        value={values.email}
        error={errors.email}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['email']}
        disabled={!editMode}
        hide={!editMode && !values.email}
      />
      {isNewUser && (
        <PasswordInput
          name="password"
          error={errors.password}
          handleChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          label={FIELDS['password']}
          hideStartAdornment
          InputProps={{
            autoComplete: 'new-password',
          }}
        />
      )}
      <ApiAutocomplete
        path="roles"
        field="role"
        value={values.role}
        label="Rol"
        handleChange={handleChangeRole}
        error={errors.role}
        showInput={!editMode || isCurrentUser}
        likeLocale={false}
        hide={!editMode && !values.role}
      />
      {(!isCurrentUser || !editMode) && (
        <PermissionsSelector
          permissions={permissions}
          editMode={editMode}
          loading={loadingPermissions}
          handleChangePermission={handleChangePermission}
          handleChangeCategory={handleChangeCategory}
        />
      )}
      <p className={classes.sectionTitle}>Información personal: </p>
      <DatePicker
        label="Fecha de nacimiento"
        value={values.birthDate}
        onChange={handleChangeDate}
        error={errors.birthDate}
        disableFuture
        disabled={!editMode}
      />
      <TextInput
        name="phoneNumber"
        value={values.phoneNumber}
        error={errors.phoneNumber}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['phoneNumber']}
        disabled={!editMode}
        hide={!editMode && !values.phoneNumber}
      />
      <CountryAutocomplete
        error={errors.birthCountry}
        label={FIELDS['birthCountry']}
        value={values.birthCountry?.name}
        handleChange={handleChangeBirthCountry}
        showInput={!editMode}
        hide={!editMode && !values.birthCountry?.code}
      />
      <CountryAutocomplete
        error={errors.currentCountry}
        label={FIELDS['currentCountry']}
        value={values.currentCountry?.name}
        handleChange={handleChangeCurrentCountry}
        showInput={!editMode}
        hide={!editMode && !values.currentCountry?.code}
      />
      <ArrayAutocomplete
        disabled={!values.currentCountry?.code}
        options={availableRegions}
        error={errors.region}
        label={FIELDS['region']}
        value={values.region}
        handleChange={handleChangeCurrentRegion}
        field="name"
        showInput={!editMode}
        hide={!editMode && !values.region?.code}
        useFieldInTextField
      />
      <p className={classes.sectionTitle}>Información profesional: </p>
      <ApiAutocomplete
        path="institutions?locale=es"
        field="name"
        value={values.institution}
        label={FIELDS['institution']}
        handleChange={handleChangeInstitution}
        error={errors.institution}
        showInput={!editMode}
        hide={!editMode && !values.institution}
      />
      <TextInput
        name="university"
        value={values.university}
        error={errors.university}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['university']}
        disabled={!editMode}
        hide={!editMode && !values.university}
      />
      <CountryAutocomplete
        error={errors.universityCountry}
        label={FIELDS['universityCountry']}
        value={values.universityCountry?.name}
        handleChange={handleChangeUniversityCountry}
        showInput={!editMode}
        hide={!editMode && !values.universityCountry?.code}
      />
      <TextInput
        name="universityYear"
        value={values.universityYear}
        error={errors.universityYear}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['universityYear']}
        disabled={!editMode}
        type="number"
        hide={!editMode && !values.universityYear}
      />
      <TextInput
        name="license"
        value={values.license}
        error={errors.license}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['license']}
        disabled={!editMode}
        hide={!editMode && !values.license}
      />
      <ApiAutocomplete
        path="professions?locale=es&hasNoFather=true"
        field="name"
        value={values.professionCategory}
        label={FIELDS['professionCategory']}
        handleChange={handleChangeProfessionCategory}
        error={errors.professionCategory}
        showInput={!editMode}
        hide={!editMode && !values.professionCategory}
      />
      <ApiAutocomplete
        path={
          values.professionCategory?._id &&
          `professions?locale=es&father=${values.professionCategory._id}`
        }
        field="name"
        value={values.professionType}
        label={FIELDS['professionType']}
        handleChange={handleChangeProfessionType}
        error={errors.professionType}
        dependsOn={values.professionCategory}
        disabled={!values.professionCategory?._id}
        showInput={!editMode}
        hide={!editMode && !values.professionType}
        freeSolo
      />
    </>
  );
};

export default withStyles(styles)(UserFormComponent);
