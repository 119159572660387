import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell as NativeTableCell,
  Tooltip,
  Typography,
  useTheme,
  withStyles,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import {
  AddPhotoAlternate as AddPhotoIcon,
  Close as CloseIcon,
  AddCircle,
} from '@material-ui/icons';
import {
  ApiAutocomplete,
  AutocompleteChipSection,
  CountryAutocompleteAdd,
  LocaleSelector,
  RichTextEditor,
  TextInput,
  Title,
  TitleNoItems,
  ImageSelector,
} from '../../common';
import { FIELDS } from './ProductsFormContainer';
import AddRankingDialog from './AddRankingDialog';
import RankingTable from './RankingTable';

const localeSelectorStyles = { margin: '15px 0px 0px' };

const ProductsFormComponent = ({
  editMode,
  errors,
  handleChange,
  handleBlur,
  values,
  history,
  hiddenFileInput,
  smallScreen,
  handleUploadImage,
  uploadingImage,
  handleCancel,
  hasChanges,
  deleteImage,
  handleSelectedValue,
  deleteRanking,
}) => {
  const [addRankingOpen, setAddRankingOpen] = useState(false);

  const handleChangeRichText = useCallback(
    value => handleChange({ target: { name: 'longDescription', value } }),
    [handleChange]
  );

  const filterCountry = useMemo(() => values.countryCodes.map(c => c.name), [values.countryCodes]);

  const handleAddCountry = useCallback(
    newValue =>
      handleChange({
        target: {
          name: 'countryCodes',
          value: [...values.countryCodes, newValue],
        },
      }),
    [handleChange, values.countryCodes]
  );

  const handleAddRanking = useCallback(
    newValue => {
      handleChange({
        target: {
          name: 'ranking',
          value: [...values.ranking, newValue],
        },
      });
      setAddRankingOpen(false);
    },
    [handleChange, values.ranking]
  );

  const handleChangeLaboratory = useCallback(
    value => handleChange({ target: { name: 'laboratory', value } }),
    [handleChange]
  );

  const handleSwitch = useCallback(
    e => {
      handleChange({ target: { name: 'isGenericProduct', value: e.target.checked } });
    },
    [handleChange]
  );

  return (
    <>
      {smallScreen && (
        <LocaleSelector
          setSelectedValue={handleSelectedValue}
          selectedValue={values.locale}
          handleCancel={handleCancel}
          hasChanges={hasChanges()}
          fullWidth
          style={localeSelectorStyles}
        />
      )}
      <TextInput
        name="name"
        value={values.name}
        label={FIELDS['name']}
        error={errors.name}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={!editMode}
      />
      <TextInput
        name="shortDescription"
        value={values.shortDescription}
        label={FIELDS['shortDescription']}
        error={errors.shortDescription}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={!editMode}
      />
      <RichTextEditor
        title="Descripción:"
        richText={values.longDescription}
        setRichText={handleChangeRichText}
        readOnly={!editMode}
        error={editMode && errors.longDescription}
      />
      {(editMode || values.web) && (
        <TextInput
          name="web"
          value={values.web}
          label={FIELDS['web']}
          error={errors.web}
          handleChange={handleChange}
          handleBlur={handleBlur}
          disabled={!editMode}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
        <FormControlLabel
          checked={values.isGenericProduct}
          control={<Switch disabled={!editMode} onChange={handleSwitch} color="primary" />}
          label={
            <Typography style={{ fontSize: 16, color: 'black' }}>Es producto genérico:</Typography>
          }
          labelPlacement="start"
          style={{ marginLeft: 0 }}
        />
      </div>
      <AutocompleteChipSection
        editMode={editMode}
        title="Principios activos"
        field="drugs"
        apiField="generics"
        history={history}
        value={values.drugs}
        handleChange={handleChange}
        noItemsText="No posee principios activos"
        selectItemText="Seleccione un principio activo"
        helpText="Si el principio activo deseado todavía no existe, deberá crearlo y luego agregarlo"
        smallScreen={smallScreen}
      />
      <AutocompleteChipSection
        editMode={editMode}
        title="Categorías"
        field="categories"
        history={history}
        value={values.categories}
        handleChange={handleChange}
        noItemsText="No posee categorías"
        selectItemText="Seleccione una categoría"
        helpText="Si la categoría deseada todavía no existe, deberá crearla y luego agregarla"
        smallScreen={smallScreen}
      />
      <AutocompleteChipSection
        editMode={editMode}
        title="Funciones"
        field="functions"
        apiField="drug-functions"
        history={history}
        value={values.functions}
        handleChange={handleChange}
        noItemsText="No posee funciones"
        selectItemText="Seleccione una función"
        helpText="Si la función deseada todavía no existe, deberá crearla y luego agregarla"
        smallScreen={smallScreen}
      />
      <AutocompleteChipSection
        editMode={editMode}
        title="Tratamientos"
        field="treatments"
        history={history}
        value={values.treatments}
        handleChange={handleChange}
        noItemsText="No posee tratamientos"
        selectItemText="Seleccione un tratamiento"
        helpText="Si el tratamiento deseado todavía no existe, deberá crearlo y luego agregarlo"
        smallScreen={smallScreen}
      />
      <>
        <TitleNoItems
          title="Países"
          text="No posee países"
          noItems={!editMode && values.countryCodes.length === 0}
        />
        {editMode && (
          <CountryAutocompleteAdd
            label={'Seleccione un país' + (smallScreen ? '' : ' para agregar')}
            smallScreen={smallScreen}
            filterCountries={filterCountry}
            onAdd={handleAddCountry}
          />
        )}
        {values.countryCodes.length > 0 && (
          <div>
            {values.countryCodes.map((i, index) => (
              <Chip
                key={index}
                style={{ marginTop: editMode ? 20 : 10, marginRight: 10 }}
                label={i.name}
                size="small"
                color="primary"
                variant="outlined"
                onDelete={
                  editMode
                    ? () =>
                        handleChange({
                          target: {
                            name: 'countryCodes',
                            value: values.countryCodes.filter(item => item.code !== i.code),
                          },
                        })
                    : undefined
                }
              />
            ))}
          </div>
        )}
      </>
      <ImageSelector
        hiddenFileInput={hiddenFileInput}
        deleteImage={deleteImage}
        handleUploadImage={handleUploadImage}
        uploadingImage={uploadingImage}
        photos={values.photos}
        mainPhoto={values.mainPhoto}
        error={errors.photos}
        onFav={i =>
          handleChange({
            target: { name: 'mainPhoto', value: { imageKey: i.imageKey, url: i.url } },
          })
        }
        editMode={editMode}
      />
      {editMode ? (
        <div>
          <Title text="Laboratorio" />
          <ApiAutocomplete
            path="laboratories"
            field="name"
            value={values.laboratory}
            title="Laboratorio"
            handleChange={handleChangeLaboratory}
            error={errors.laboratory}
            history={history}
            likeLocale={false}
          />
        </div>
      ) : (
        <TextInput name="laboratory" value={values.laboratory.name} label="Laboratorio" disabled />
      )}
      <TitleNoItems
        title="Rankings"
        text="No posee rankings"
        noItems={!editMode && values.ranking.length === 0}
      />
      {(editMode || values.ranking.length > 0) && (
        <RankingTable
          ranking={values.ranking}
          setAddRankingOpen={setAddRankingOpen}
          deleteRanking={deleteRanking}
          editMode={editMode}
          smallScreen={smallScreen}
        />
      )}
      <AddRankingDialog
        handleAdd={handleAddRanking}
        open={addRankingOpen}
        handleClose={() => setAddRankingOpen(false)}
      />
    </>
  );
};

export default ProductsFormComponent;
