export default () => ({
  radioGroup: {
    flexDirection: 'row',
    marginBottom: 8,
    alignItems: 'flex-end',
  },
  helpIcon: {
    color: '#707070',
    marginBottom: 12,
    marginLeft: 5,
    fontSize: 20,
  },
});
