import React from 'react';
import { Button, CircularProgress, Grid, Typography, withStyles } from '@material-ui/core';
import { ErrorToast, SuccessToast, PasswordInput } from '../../../components/common';
import styles from './styles';
import logo from '../../../assets/logo.png';

const PasswordResetFormComponent = ({
  classes,
  data,
  handleSubmit,
  handleChange,
  handleSuccess,
  onDismissError,
}) => {
  const {
    loading,
    errorpassword,
    password,
    errorpasswordRepeat,
    passwordRepeat,
    errortoken,
    success,
  } = data;
  return (
    <div className={classes.background}>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.gridLogin}
      >
        <form className={classes.formLogin} onSubmit={handleSubmit}>
          <img src={logo} width={200} alt="Logo" style={{ margin: '0 auto' }} />
          <Typography component="h1" variant="h5" className={classes.title}>
            Cambiar contraseña
          </Typography>
          <div className={classes.inputLogin}>
            <PasswordInput
              error={errorpassword}
              name="password"
              placeholder="Contraseña"
              handleChange={handleChange}
              value={password}
              variant="outlined"
            />
          </div>
          <div className={classes.inputLogin}>
            <PasswordInput
              error={errorpasswordRepeat}
              name="passwordRepeat"
              placeholder="Contraseña"
              handleChange={handleChange}
              value={passwordRepeat}
              variant="outlined"
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            Confirmar contraseña
          </Button>
          <p onClick={handleSuccess} className={classes.goLogin}>
            Volver al login
          </p>
        </form>
      </Grid>
      <ErrorToast
        open={errortoken !== ''}
        onClose={onDismissError}
        text="Enlace invalido. Solicite el cambio de contraseña nuevamente."
      />
      <SuccessToast
        open={success}
        onClose={handleSuccess}
        duration={3000}
        text="Contraseña cambiada correctamente!"
      />
    </div>
  );
};

export default withStyles(styles)(PasswordResetFormComponent);
