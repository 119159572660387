import React from 'react';
import { TableContainer } from '../common';

export const MESSAGES = {
  title: 'Listado de funciones',
  newButton: 'Nueva funcion',
  created: 'La función ha sido creada correctamente',
  edited: 'La función ha sido editada correctamente',
  deleted: 'La función ha sido eliminada correctamente',
  deleteConfirmation: treatment =>
    `¿Estas seguro/a de que queres eliminar la función ${treatment.name}?`,
};

const COLUMNS = [
  {
    id: 'name',
    label: 'Nombre',
    minWidth: 100,
  },
];

export const ENTITY_PROPS = {
  entityName: 'función',
  endpoint: 'drug-functions',
  path: '/home/drug-functions',
};

const FunctionsTable = () => {
  return <TableContainer entityProps={ENTITY_PROPS} messages={MESSAGES} columns={COLUMNS} />;
};

export default FunctionsTable;
