import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import validate from './rankingValidator';
import {
  ApiAutocomplete,
  ArrayAutocomplete,
  CountryAutocomplete,
  TextInput,
  useFormValidation,
} from '../../common';

const INITIAL_STATE = {
  country: '',
  searchType: '',
  key: '',
  position: '',
};

export const SEARCH_TYPES = {
  treatments: { name: 'Tratamientos', path: 'treatments' },
  functions: { name: 'Funciones', path: 'drug-functions' },
  categories: { name: 'Categorías', path: 'categories' },
  laboratory: { name: 'Laboratorio', path: 'laboratories' },
  drugs: { name: 'Drogas', path: 'generics' },
};

const SEARCH_TYPES_LIST = Object.entries(SEARCH_TYPES).map(s => ({
  value: s[0],
  name: s[1].name,
  path: s[1].path,
}));

const AddRankingDialog = ({ open, handleClose, handleAdd }) => {
  const { handleSubmit, handleChange, handleBlur, values, errors, resetValues } = useFormValidation(
    INITIAL_STATE,
    values => validate(values)
  );

  const handleChangeAutocomplete = useCallback(
    (name, value) => {
      handleChange({
        target: {
          name: name,
          value: value,
        },
      });
    },
    [handleChange]
  );

  const handleChangeCountry = useCallback(
    value => handleChangeAutocomplete('country', value),
    [handleChangeAutocomplete]
  );

  const handleChangeSearchType = useCallback(
    value => handleChangeAutocomplete('searchType', value),
    [handleChangeAutocomplete]
  );

  const handleChangeKey = useCallback(
    value => handleChangeAutocomplete('key', value),
    [handleChangeAutocomplete]
  );

  const onAdd = e => {
    const formValid = handleSubmit(e);
    if (formValid) {
      const { country, searchType, key, position } = values;
      handleAdd({
        key,
        position,
        searchType: searchType.value,
        country: country.code.split('-')[0],
      });
      resetValues(INITIAL_STATE);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Agregar fila al Ranking</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <DialogContentText>Complete los datos para agregar una fila al ranking</DialogContentText>
        <CountryAutocomplete
          error={errors.country}
          label="País"
          value={values.country?.name}
          handleChange={handleChangeCountry}
        />
        <ArrayAutocomplete
          options={SEARCH_TYPES_LIST}
          error={errors.searchType}
          label="Tipo de búsqueda"
          value={values.searchType}
          handleChange={handleChangeSearchType}
          field="value"
          nameField="name"
        />
        <ApiAutocomplete
          path={values.searchType?.path}
          field="name"
          value={values.key}
          label="Valor de búsqueda"
          handleChange={handleChangeKey}
          helperText={!values.searchType?.path && 'Primero debe seleccionar el típo de búsqueda'}
          error={errors.key}
        />
        <TextInput
          name="position"
          value={values.position}
          error={errors.position}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label="Posición"
          type="number"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onAdd} color="primary">
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRankingDialog;
