import React, { useCallback } from 'react';
import { ApiAutocomplete, LocaleSelector, TextInput } from '../../common';
import { FIELDS } from './CategoriesFormContainer';

const localeSelectorStyles = { margin: '15px 0px 0px' };

const CategoriesFormComponent = ({
  editMode,
  errors,
  handleChange,
  handleBlur,
  values,
  smallScreen,
  hasChanges,
  handleCancel,
  handleSelectedValue,
  isNew,
  id,
}) => {
  const handleChangeFather = useCallback(
    value => handleChange({ target: { name: 'father', value } }),
    [handleChange]
  );
  return (
    <>
      {smallScreen && (
        <LocaleSelector
          fullWidth
          style={localeSelectorStyles}
          setSelectedValue={handleSelectedValue}
          selectedValue={values.locale}
          hasChanges={hasChanges()}
          handleCancel={handleCancel}
        />
      )}
      <TextInput
        name="name"
        value={values.name}
        error={errors.name}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['name']}
        disabled={!editMode}
      />
      <TextInput
        name="description"
        value={values.description}
        label={FIELDS['description']}
        error={errors.description}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={!editMode}
        hide={!values.description && !editMode}
      />
      {(editMode || values.father._id) && (
        <ApiAutocomplete
          path="categories?hasNoFather=true"
          field="name"
          label={`Categoría padre ${isNew ? '(opcional)' : ''}`}
          value={values.father}
          handleChange={handleChangeFather}
          error={errors.father}
          showInput={!editMode}
          filterIds={[id]}
          likeLocale
        />
      )}
    </>
  );
};

export default CategoriesFormComponent;
