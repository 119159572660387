import React from 'react';
import { Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { Star, StarBorder, Delete, OpenInNew } from '@material-ui/icons';

const MyIcon = ({ icon, onClick, style = {} }) =>
  React.createElement(icon, {
    style: { color: 'white', fontSize: 28, ...style },
    onClick,
  });

const DiseaseImage = ({ isMain, onFav, onDelete, image, editMode }) => {
  const [showActions, setShowActions] = React.useState(false);
  const handleOpen = () => window.open(image);

  return (
    <div
      style={{
        display: 'flex',
        marginRight: 10,
        marginBottom: 10,
        position: 'relative',
      }}
      onMouseOver={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: showActions ? 'rgba(0,0,0,0.5)' : 'transparent',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          zIndex: 1000,
          cursor: 'pointer',
        }}
      >
        {showActions &&
          editMode &&
          (isMain ? (
            <MyIcon onClick={onFav} icon={Star} />
          ) : (
            <MyIcon onClick={onFav} icon={StarBorder} />
          ))}
        {showActions && <MyIcon onClick={handleOpen} icon={OpenInNew} />}
        {showActions && editMode && <MyIcon onClick={onDelete} icon={Delete} />}
      </div>
      <CardMedia
        component="img"
        height={150}
        image={image}
        style={{ objectFit: 'cover', width: 150 }}
        alt="Imagen"
      />
      {isMain && (
        <div
          style={{
            width: '100%',
            height: '15%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography style={{ color: 'white', fontSize: 14, fontWeight: 500 }}>
            IMAGEN PRINCIPAL
          </Typography>
        </div>
      )}
    </div>
  );
};

export default DiseaseImage;
